import * as React from 'react';
import {SVGProps} from 'react';

export const IconPairing = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 512 512"
        {...props}
    >
        <path
            d="M264.181 76.909c-93.646 0-169.561 75.915-169.561 169.561s75.915 169.561 169.561 169.561 169.561-75.915 169.561-169.561S357.827 76.909 264.181 76.909zm-.001 298.22c-70.942 0-128.658-57.716-128.658-128.658s57.716-128.658 128.658-128.658 128.658 57.716 128.658 128.658-57.715 128.658-128.658 128.658z"/>
        <path
            d="M264.18 152.299c-51.926 0-94.171 42.245-94.171 94.171 0 51.926 42.245 94.171 94.171 94.171 51.926 0 94.171-42.245 94.171-94.171s-42.244-94.171-94.171-94.171zM501.315 260.687V54.64a4.632 4.632 0 0 0-8.321-2.799 195.013 195.013 0 0 0-39.622 117.83v75.378c0 8.645 7.008 15.654 15.654 15.654h6.526c-6.433 66.443-10.684 159.37-10.684 170.251 0 17.142 10.551 31.038 23.566 31.038 13.015 0 23.566-13.897 23.566-31.038 0-10.882-4.251-103.824-10.685-170.267zM68.417 219.843c13.042-7.9 21.759-22.224 21.759-38.586l-6.46-105.621a7.632 7.632 0 0 0-15.24.845l4.201 84.709a8.621 8.621 0 0 1-17.242 0l-2.099-84.674a8.25 8.25 0 0 0-16.494 0l-2.099 84.674a8.621 8.621 0 0 1-17.242 0l4.201-84.709c.216-4.357-3.262-8.01-7.622-8.01a7.63 7.63 0 0 0-7.617 7.165L0 181.258c0 16.362 8.716 30.685 21.759 38.586a25.5 25.5 0 0 1 12.126 24.617c-7.363 66.358-12.363 174.693-12.363 186.494 0 17.142 10.551 31.038 23.566 31.038 13.015 0 23.566-13.897 23.566-31.038 0-11.801-5.001-120.136-12.363-186.494a25.51 25.51 0 0 1 12.126-24.618z"/>
    </svg>
);
