import * as React from 'react';
import {SVGProps} from 'react';

export const IconGrapes = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6.35 6.35"
        {...props}
    >
        <path
            d="M1.898 1.518a.978.978 0 0 0-.777.485.979.979 0 0 0 .356 1.332c.16.092.334.13.506.126a1.228 1.228 0 0 1 .134-.869c.16-.279.418-.468.706-.558a.965.965 0 0 0-.37-.39.965.965 0 0 0-.555-.126zM4.38 2.943c.066.291.032.607-.128.883a1.21 1.21 0 0 1-.68.544.966.966 0 0 0 .37.39.979.979 0 0 0 1.334-.356.982.982 0 0 0-.358-1.334.966.966 0 0 0-.538-.127zM1.124 3.422l-.006.01a.98.98 0 0 0 .358 1.332.978.978 0 0 0 1.333-.355l.015-.03a1.217 1.217 0 0 1-.734-.642 1.234 1.234 0 0 1-.966-.315zM3.323 4.42c-.07.002-.147-.005-.217.004-.29.46-.498.578-.797.661a.968.968 0 0 0 .384.412.98.98 0 0 0 1.333-.358c.008-.014.014-.028.02-.043-.085-.026-.165-.07-.251-.107-.215-.09-.381-.294-.472-.57zM1.154 4.775c-.028.036-.056.07-.08.111a.982.982 0 0 0 .358 1.335.98.98 0 0 0 1.333-.358c.01-.017.016-.034.025-.051a1.212 1.212 0 0 1-.748-.734 1.209 1.209 0 0 1-.888-.303z"/>
        <path
            d="M3.68 2.367a.98.98 0 0 0-1.334.358.98.98 0 0 0 .359 1.332.978.978 0 0 0 1.332-.355.982.982 0 0 0-.358-1.335z"/>
        <path
            d="M4.942 1.295c-.428.005-.777.139-1.045.344s-.46.47-.621.738a.265.265 0 0 0 .09.363.265.265 0 0 0 .363-.09c.147-.243.301-.45.488-.591.186-.143.403-.231.73-.235a.265.265 0 0 0 .262-.267.265.265 0 0 0-.267-.262z"/>
        <path
            d="M3.168.014C3.064.003 2.993-.028 2.84.06c-.153.089-.16.164-.203.26a1.47 1.47 0 0 0-.104.34c-.046.26-.044.585.127.883.172.298.454.463.702.553.123.044.24.069.345.08s.173.041.326-.047c.154-.089.162-.164.205-.26a1.47 1.47 0 0 0 .102-.34c.047-.259.045-.585-.127-.883-.172-.297-.452-.463-.7-.552-.113-.045-.24-.06-.345-.08z"/>
    </svg>
);
