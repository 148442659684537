'use client';
import {FilterType, useAppDispatch, useAppSelector} from '../../../../store';
import {Label, Slider} from '@repo/ui';
import {useEffect, useState} from 'react';
import {currencyMap} from '@repo/utils';
import {setAppliedFilters} from '../../../../store/app-state-slice';
import {useDebouncedValue} from '../../../use-debounced-value';

export const PriceFilterComponent = () => {
    const dispatch = useAppDispatch();
    const {
        appliedFilters,
        minPrice,
        maxPrice,
        minPricePerGlass,
        maxPricePerGlass,
        currency,
    } = useAppSelector(state => state.appState);

    const min = appliedFilters[FilterType.ByGlass] ? minPricePerGlass : minPrice;
    const max = appliedFilters[FilterType.ByGlass] ? maxPricePerGlass : maxPrice;
    const [desiredPrice, setDesiredPrice] = useState<number>(appliedFilters[FilterType.Price] ?? max);
    const debouncedDesiredPrice = useDebouncedValue(desiredPrice, 300);

    useEffect(() => {
        const newPrice = debouncedDesiredPrice === max ? max : debouncedDesiredPrice;
        dispatch(setAppliedFilters({
            ...appliedFilters,
            [FilterType.Price]: newPrice
        }));
    }, [debouncedDesiredPrice]);

    const onMaxPriceChanged = (value: [number]) => {
        setDesiredPrice( value[0]);
    };

    return <div className={'min-h-[400px] md:w-60'}>
        <div className={'flex flex-col space-y-4 h-96 pl-28 pt-4'}>
            <Label className={'text-3xl text-buttonText'}>{max}&nbsp;<sup>{currencyMap[currency]}</sup></Label>
            <Slider
                valuelabel={<Label className={'text-3xl text-buttonText'}>{desiredPrice}&nbsp;
                    <sup>{currencyMap[currency]}</sup></Label>}
                defaultValue={[appliedFilters[FilterType.Price] ?? max]}
                max={max}
                min={min}
                step={10}
                orientation={'vertical'}
                className={'ml-4 h-full bg-background'}
                onValueChange={onMaxPriceChanged}
            />
            <Label className={'text-3xl text-buttonText'}>{min}&nbsp;<sup>{currencyMap[currency]}</sup></Label>
        </div>
    </div>;
};

