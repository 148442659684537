import * as React from 'react';
import {SVGProps} from 'react';

export const IconNew = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 30"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="m29.955 14.386-1.778-3.037V7.844a.866.866 0 0 0-.44-.752l-3.081-1.753-1.779-3.036a.884.884 0 0 0-.763-.434h-3.556L15.477.117a.892.892 0 0 0-.881 0l-3.08 1.752H7.958a.883.883 0 0 0-.763.434L5.417 5.34l-3.08 1.753a.866.866 0 0 0-.44.752v3.505l-1.78 3.037a.857.857 0 0 0 0 .868l1.78 3.036v3.505c0 .31.167.597.44.752l3.08 1.753 1.779 3.036a.883.883 0 0 0 .763.434h3.556l3.081 1.753a.89.89 0 0 0 .881 0l3.08-1.753h3.557a.884.884 0 0 0 .763-.434l1.779-3.036 3.08-1.753a.866.866 0 0 0 .44-.752V18.29l1.78-3.036a.857.857 0 0 0 0-.868ZM10.727 17.61a.87.87 0 0 1-.626.83.888.888 0 0 1-.988-.349l-2.16-3.194v2.713c0 .48-.395.868-.882.868a.875.875 0 0 1-.88-.868v-5.58a.87.87 0 0 1 .625-.832.888.888 0 0 1 .988.35l2.161 3.194v-2.713c0-.48.394-.868.881-.868s.881.389.881.868v5.58Zm3.838-3.659c.486 0 .88.389.88.869s-.394.868-.88.868h-1.007v1.053h1.95c.487 0 .881.39.881.869 0 .48-.394.868-.88.868h-2.832a.875.875 0 0 1-.88-.868v-5.58c0-.48.394-.869.88-.869h2.831c.487 0 .881.389.881.868 0 .48-.394.869-.88.869h-1.95v1.053h1.006Zm10.763-1.711-1.415 5.58a.878.878 0 0 1-.855.658.88.88 0 0 1-.836-.594l-1.052-3.109-1.051 3.11a.882.882 0 0 1-.87.592.878.878 0 0 1-.82-.657l-1.416-5.58a.867.867 0 0 1 .64-1.053.882.882 0 0 1 1.07.632l.681 2.687.93-2.751a.88.88 0 0 1 .836-.594c.38 0 .716.24.836.594l.93 2.751.683-2.687a.882.882 0 0 1 1.068-.632c.472.116.76.588.641 1.053Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path d="M0 0h30.073v29.639H0z"/>
            </clipPath>
        </defs>
    </svg>
);
