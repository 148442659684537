import * as React from 'react';
import {SVGProps} from 'react';

export const IconFeaturedV2 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 30"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M29.42 13.49c.592-.569.801-1.403.546-2.179a2.135 2.135 0 0 0-1.741-1.447l-7.282-1.042a.95.95 0 0 1-.717-.514l-3.255-6.502A2.147 2.147 0 0 0 15.037.62c-.827 0-1.568.454-1.934 1.185L9.847 8.309a.951.951 0 0 1-.717.513L1.848 9.865a2.135 2.135 0 0 0-1.741 1.447 2.091 2.091 0 0 0 .545 2.179l5.269 5.06a.931.931 0 0 1 .274.832l-1.243 7.146a2.06 2.06 0 0 0 .47 1.724 2.201 2.201 0 0 0 2.66.517l6.511-3.375a.987.987 0 0 1 .887 0l6.513 3.375a2.175 2.175 0 0 0 2.658-.517c.413-.483.58-1.096.47-1.724l-1.244-7.146a.93.93 0 0 1 .275-.831l5.269-5.062Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path d="M0 0h30.073v29.639H0z"/>
            </clipPath>
        </defs>
    </svg>
);
