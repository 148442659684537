'use client';
import {FilterType, useAppDispatch, useAppSelector} from '../../../../store';
import {useClientTranslation} from '@repo/internationalization/client';
import {cn, ComboBox, ComboBoxItem, Label} from '@repo/ui';
import {setAppliedFilters} from '../../../../store/app-state-slice';
import {Responsive} from '@repo/utils';

export const WineRegionFilterComponent = () => {

    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const {appliedFilters, uniqueCountries, uniqueRegions} = useAppSelector(state => state.appState);
    const searchCountryPlaceholder = t('menus.filterPanel.wineRegion.country.placeholder.label');
    const searchRegionPlaceholder = t('menus.filterPanel.wineRegion.region.placeholder.label');
    const maxOptionsSelectedLabel = t('menus.filterPanel.wineRegion.maxOptionsSelected.label', {maxOptions: 3});
    const panelTitle = t('menus.filterPanel.labels.title');

    const countryComboBoxItems = uniqueCountries?.map(country => {
        return {
            value: country.name,
            label: t(`countries.${country.name}`)
        };
    });

    const regionComboBoxItems = uniqueRegions?.map(region => {
        return {
            value: region.name,
            label: t(`regions.${region.name}`)
        };
    });

    const onCountrySelected = (selectedItems: ComboBoxItem[]) => {
        dispatch(setAppliedFilters({
            ...appliedFilters,
            [FilterType.Region]: {
                countries: selectedItems,
                regions: []
            }
        }));
    };

    const onRegionSelected = (selectedItems: ComboBoxItem[]) => {
        dispatch(setAppliedFilters({
            ...appliedFilters,
            [FilterType.Region]: {
                countries: appliedFilters[FilterType.Region].countries,
                regions: selectedItems
            }
        }));
    };

    return <div className={'flex flex-col md:flex-row min-h-[400px]'}>
        <div className={cn('flex flex-col overflow-clip grow md:w-60', Responsive.isMobileView() ? '' : 'mr-2')}>
            <Label className={'text-lg md:text-xl font-bold text-buttonText'}>{panelTitle}</Label>
            <ComboBox
                className={'text-buttonText'}
                items={countryComboBoxItems}
                defaultValue={appliedFilters[FilterType.Region].countries}
                noOptionsMessage={maxOptionsSelectedLabel}
                placeholder={searchCountryPlaceholder}
                onItemsSelected={onCountrySelected}
            />
        </div>
        <div className={'flex flex-col overflow-clip grow md:w-60'}>
            <div className={Responsive.isMobileView() ? 'mt-4' : 'mt-7'}/>
            <ComboBox
                className={'text-buttonText'}
                items={regionComboBoxItems}
                defaultValue={appliedFilters[FilterType.Region].regions}
                noOptionsMessage={maxOptionsSelectedLabel}
                placeholder={searchRegionPlaceholder}
                onItemsSelected={onRegionSelected}
            />
        </div>
    </div>;
};