'use client';
import i18next from 'i18next';
import { initReactI18next, useTranslation as useTranslationOrg, } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
// import LocizeBackend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultNS, getOptions, languages } from '../settings';
import { useEffect } from 'react';
import { useCookies } from 'next-client-cookies';

const runsOnServerSide = typeof window === 'undefined';

let hasInit = false;

const initialize = (lng: string | undefined) => {
    if (hasInit) {
        return;
    }
    hasInit = true;

    // on client side the normal singleton is ok
    i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(
            resourcesToBackend(
                (language: string, namespace: string) =>
                    import(`../../public/locales/${language}/${namespace}.json`)
            )
        )
        // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with
        // server side
        .init({
            ...getOptions(),
            lng: lng, // let detect the language on client side
            detection: {
                order: ['cookie', 'path', 'htmlTag', 'navigator'],
            },
            preload: runsOnServerSide ? languages : [],
        });
};


export interface ClientTranslationOpts {
    lng?: string;
    ns?: string | string[];
    options?: any;
}

export function useClientTranslation(opts?: ClientTranslationOpts) {
    const { lng, ns = defaultNS, options = {} } = opts ?? {};
    const cookies = useCookies();
    const lang = cookies.get('lang') ?? lng ?? 'ro';
    initialize(lang);
    const ret = useTranslationOrg(ns, {
        ...options,
        lng: lang
    });

    const { i18n } = ret;

    useEffect(() => {
        if (i18n.resolvedLanguage === lang) return;
        i18n.changeLanguage(lang);
    }, [lang, i18n]);
    return ret;
}
