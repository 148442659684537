import * as React from 'react';
import {SVGProps} from 'react';

export const IconWineAroma = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 54 57"
        {...props}
    >
        <path
            d="M.634 52h4.212l9.092-9.093-1.857-2.354zM53.933 15.247 38.763.077a.246.246 0 0 0-.321-.027l-13.53 10.4a14.023 14.023 0 0 0 2.5 3.265 13.561 13.561 0 0 0 6.508 3.8 15.4 15.4 0 0 1 7.3 4.276 14.9 14.9 0 0 1 3.68 5.546l9.045-11.776a.237.237 0 0 0-.012-.314zm-12-7.762a1 1 0 0 1-1.414 0l-2.257-2.253-2.276 1.749A1 1 0 1 1 34.768 5.4l2.971-2.284a1 1 0 0 1 1.317.086l2.873 2.873a1 1 0 0 1 0 1.41zM42.349 37.02a8.742 8.742 0 0 1-5.572-3.72l-5.319-7.968a3.002 3.002 0 1 0-4.995 3.331l1.963 3.561c.536.806.82 1.754.814 2.722A5.024 5.024 0 0 1 24.351 40a5.173 5.173 0 0 1-4.135-1.919l-3.888-5.051a17.951 17.951 0 0 1-2.947-16.83L15.8 8.943a2.994 2.994 0 1 0-5.682-1.89l-3.091 9.3a19.929 19.929 0 0 0 3.276 18.716l7.91 10.03a9.006 9.006 0 0 0 3.069 2.484l14.251 7.079a12.922 12.922 0 0 0 5.777 1.358c3.063 0 8.113-.01 11.216-.02.3.002.585-.135.771-.371a1 1 0 0 0 .2-.843l-2.109-9.621a11.962 11.962 0 0 0-9.039-8.145z"/>
        <path
            d="M26.185 22.839a5.009 5.009 0 0 1 6.936 1.386l5.321 7.969a6.673 6.673 0 0 0 1.5 1.6l3.495-4.549a13.153 13.153 0 0 0-3.637-6.039 13.537 13.537 0 0 0-6.485-3.782 15.413 15.413 0 0 1-7.324-4.3 16.282 16.282 0 0 1-2.691-3.438l-8.386 6.445A15.946 15.946 0 0 0 17.9 31.8l3.888 5.05a3.105 3.105 0 0 0 2.5 1.15 3.036 3.036 0 0 0 2.951-3.05 2.952 2.952 0 0 0-.518-1.679L24.754 29.7a5 5 0 0 1 1.431-6.861zM21.9 19.792a4.531 4.531 0 0 0-1.889 3.838c.123 1.16.447 2.289.957 3.337a1 1 0 0 1-1.811.851A11.879 11.879 0 0 1 18.02 23.8a6.535 6.535 0 0 1 2.661-5.593 1 1 0 1 1 1.22 1.584z"/>
    </svg>
);
