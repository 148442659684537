'use client';
import {useEffect, useState} from 'react';

export const useDebouncedValue = <T extends unknown>(inputValue: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, delay]);

    return debouncedValue;
};