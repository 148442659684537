'use client';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@repo/ui';
import { useCookies } from 'next-client-cookies';
import { useAppDispatch, useAppSelector } from '../../store';
import { setLang } from '../../store/app-state-slice';
import { useEffect } from 'react';


export const LanguageSelector = () => {
    const cookies = useCookies();
    const lang = cookies.get('lang')?.toUpperCase() || 'EN';
    const restaurant = useAppSelector(state => state.appState.restaurant);
    const dispatch = useAppDispatch();
    const restaurantLanguages = restaurant?.supportedLanguages || [];

    const onLanguageChange = (language: string) => {
        cookies.set('lang', language.toLowerCase());
        dispatch(setLang(language));
    }

    const languageTranslations: Record<string, string> = {
        'EN': 'EN',
        'RO': 'RO',
        'ES': 'ES',
        'FR': 'FR',
        'IT': 'IT',
        'DE': 'DR',
        'HU': 'HU'
    };
    return <Select
        onValueChange={(item) => {
            if (item === lang) {
                return;
            }
            onLanguageChange(item);
        }}
    >
        <SelectTrigger
            className="w-18 text-xl bg-button text-buttonText z-52"
        >
            <SelectValue
                placeholder={languageTranslations[lang]}
            />
        </SelectTrigger>
        <SelectContent className={'z-52'}>
            {restaurantLanguages.map(language => {
                return <SelectItem
                    key={language}
                    value={language}
                    className={'text-2xl'}
                >
                    {languageTranslations[language]}
                </SelectItem>;
            })}
        </SelectContent>
    </Select>;
};
