import {ApiMenuItem} from '@repo/api/model';
import {IconNew} from '../../assets/icons/icon-new';
import {IconFeaturedV2} from '../../assets/icons/icon-featured-v2';
import {IconOffer} from '../../assets/icons/icon-offer';

export const MedalsComponent = (props: { item: ApiMenuItem, orientation: 'horizontal' | 'vertical' }) => {
    const {item, orientation} = props;
    return (item.isNew || item.isFeatured || item.priceOffer) ?
        <div className={`flex flex-row space-x-2 h-8 items-center mr-2`}>
            {item.isNew && <IconNew width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
            {item.isFeatured &&
                <IconFeaturedV2 width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
            {item.priceOffer && <IconOffer width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
        </div>
        : null;
};

// export const MedalsComponent = (props: { item: ApiMenuItem, orientation: 'horizontal' | 'vertical' }) => {
//     const {item, orientation} = props;
//     return (item.isNew || item.isFeatured || item.priceOffer) ?
//         <div className={`flex ${orientation === 'horizontal'? 'flex-row space-x-2 h-8': 'flex-col space-y-2 w-8'} items-center mr-2`}>
//             {item.isNew && <IconNew width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
//             {item.isFeatured &&
//                 <IconFeaturedV2 width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
//             {item.priceOffer && <IconOffer width={16} height={16} className={'w-4 h-4 md:h-6 md:w-6'}/>}
//         </div>
//         : null;
// };
