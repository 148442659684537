import * as React from 'react';
import {SVGProps} from 'react';

export const IconWineType = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 448"
        {...props}
    >
        <path
            d="M0 248h128v112H0zM0 376h128v72H0zM48 0h32v16H48zM128 184c.004-18.234-12.324-34.168-29.977-38.734A24.005 24.005 0 0 1 80 122.09V32H48v90.09c-.02 10.937-7.438 20.476-18.031 23.2C12.329 149.86.004 165.776 0 184v48h128zM248 304c0 13.254-10.746 24-24 24s-24-10.746-24-24 10.746-24 24-24 24 10.746 24 24zm0 0"/>
        <path
            d="M208 99.29a135.882 135.882 0 0 1-20.672 72.085l-1.113 1.762A119.871 119.871 0 0 0 168 236.71V448h112V236.71a119.897 119.897 0 0 0-18.23-63.597l-1.082-1.715A135.942 135.942 0 0 1 240 99.29V32h-32zM264 304c0 22.09-17.91 40-40 40s-40-17.91-40-40 17.91-40 40-40c22.082.027 39.973 17.918 40 40zM208 0h32v16h-32zM352 208h64v192h-64zM368 0h32v16h-32zm0 0"/>
        <path
            d="M320 147.375V448h128V147.375a87.837 87.837 0 0 0-39.055-73.137l-.144-.086A19.745 19.745 0 0 1 400 57.703V32h-32v25.703a19.745 19.745 0 0 1-8.8 16.45l-.145.093A87.794 87.794 0 0 0 320 147.375zM424 192a8 8 0 0 1 8 8v208a8 8 0 0 1-8 8h-80a8 8 0 0 1-8-8V200a8 8 0 0 1 8-8zm0 0"/>
    </svg>
);
