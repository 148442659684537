'use client';
import {WineItemRow} from './wine-item-row';
import {ApiMenuItem, ApiMenuItemDisplayType} from '@repo/api/model';
import {FoodItemRow} from './food-item-row';
import {MiscItemRow} from './misc-item-row';

export const renderRow = (item: ApiMenuItem) => {
    switch (item.displayType) {
        case ApiMenuItemDisplayType.FOOD: {
            return <FoodItemRow
                item={item}
                key={item.id}
            />;
        }
        case ApiMenuItemDisplayType.WINE: {
            return <WineItemRow
                item={item}
                key={item.id}
            />;
        }
        case ApiMenuItemDisplayType.COCKTAIL: {
            return <FoodItemRow
                item={item}
                key={item.id}
            />;
        }
        case ApiMenuItemDisplayType.MISC: {
            return <MiscItemRow
                item={item}
                key={item.id}
            />;
        }
        case ApiMenuItemDisplayType.DRINK: {
            return <FoodItemRow
                item={item}
                key={item.id}
            />;
        }
        default: {
            return <MiscItemRow
                item={item}
                key={item.id}
            />;
        }
    }
};
