'use client';
import {FilterProps} from './filter-props';
import {useClientTranslation} from '@repo/internationalization/client';
import {Label} from '@repo/ui';
import React from 'react';

export const Filter = (props: FilterProps) => {
    const {filterName, icon: Icon, labelKey, onClick, isActive} = props;
    const {t} = useClientTranslation();

    const translatedFilterName = t(labelKey);
    return (
        <div
            className={`flex flex-col mt-1 w-18 h-18 p-2 rounded-lg justify-center items-center cursor-pointer bg-button ${isActive ? 'filter brightness-90' : ''}`}
            onClick={() => onClick && onClick(filterName)}
        >
            <Icon width={40} height={40} className={'fill-white'}/>
            <Label className={'text-lg font-bold  text-white'}>
                {translatedFilterName}
            </Label>
        </div>
    );
};