'use client';
import { ClientTranslatableText, Label, useClientTranslatableText } from '@repo/ui';
import { ApiMenuItem } from '@repo/api/model';
import { memo } from 'react';
import { renderRow } from './render-row';
import { Blockquote } from "../blockquote";

export interface SubsectionCardProps {
    item: ApiMenuItem;
}

export const SubsectionCard = (props: SubsectionCardProps) => {
    const { item } = props;

    const description = useClientTranslatableText(item.shortDescription);
    const name = useClientTranslatableText(item.name);
    return <div key={item.id}
        id={'subsection_card'}
        className={'flex flex-col mt-4'}
    >
        <div className={''}>
            <h3 className="text-xl uppercase text-button flex items-end py-2">
                <Label className="text-base sm:text-2xl">
                    {name}
                </Label>
                <div className="border-b-2 border-button flex-grow mb-1.5 ml-1" />
            </h3>
            {description ? <Blockquote>{description}</Blockquote> : null}
        </div>
        {item.items?.map((subItem) => renderRow(subItem))}
    </div>;
};


