import * as React from 'react';
import {SVGProps} from 'react';

export const IconThermometer = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 512 512"
        {...props}
    >
        <path
            d="M357.238 329.726V69.745C357.238 31.287 325.949 0 287.492 0c-38.45 0-69.732 31.287-69.732 69.744v259.981C146.628 393.683 192.014 512 287.493 512c95.439 0 140.878-118.308 69.745-182.274zm-37.463-100.762h-64.551V69.744c0-17.8 14.476-32.281 32.268-32.281 17.8 0 32.282 14.481 32.282 32.281v159.22zM170.232 237.268h-31.484c-10.345 0-18.732 8.387-18.732 18.732 0 10.345 8.387 18.732 18.732 18.732h31.484c10.345 0 18.732-8.387 18.732-18.732-.001-10.345-8.388-18.732-18.732-18.732zM170.232 175.566h-31.484c-10.345 0-18.732 8.387-18.732 18.732 0 10.345 8.387 18.732 18.732 18.732h31.484c10.345 0 18.732-8.387 18.732-18.732-.001-10.345-8.388-18.732-18.732-18.732zM170.232 113.864h-31.484c-10.345 0-18.732 8.387-18.732 18.732s8.387 18.732 18.732 18.732h31.484c10.345 0 18.732-8.387 18.732-18.732s-8.388-18.732-18.732-18.732z"/>
    </svg>
);

