'use client';
import Image from 'next/image';
import { ClientTranslatableText, Label, useClientTranslatableText } from '@repo/ui';
import { twMerge } from 'tailwind-merge';
import { ApiMenu } from '@repo/api/model';
import { useLinkClickHandler } from 'react-router-dom';
import React from 'react';
import { useAppDispatch } from '../../store';
import { setAppliedFilters, setOpenedSections, setSelectedMenuId } from '../../store/app-state-slice';
import { useLazyGetMenuQuery } from '../../store/api/restaurant-menu-api';
import { getDefaultAppliedFilters } from '../../store/initial-state';

export interface MenuItemProps {
    menu: ApiMenu;
    className?: string;
    restaurantSlug: string;
    targetLang: string;
}

export const RestaurantMenu = (props: MenuItemProps) => {
    const { menu, className, restaurantSlug } = props;
    const linkClickHandler = useLinkClickHandler(menu.id);
    const dispatch = useAppDispatch();
    const [lazyRefetchMenu] = useLazyGetMenuQuery();

    const menuName = useClientTranslatableText(menu.name);

    const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        dispatch(setOpenedSections([]));
        dispatch(setSelectedMenuId(menu.id));
        dispatch(setAppliedFilters(getDefaultAppliedFilters()));
        lazyRefetchMenu({
            restaurantSlug: restaurantSlug,
            menuId: menu.id,
            expand: ['sections'],
        });
        linkClickHandler(event);
    };

    return (
        <a
            onClick={onClick}
            className={twMerge('rounded-2xl flex flex-row items-center shadow-2xl w-full text-left', className)}
        >
            {menu.image && (
                <div className="w-40 h-20 relative">
                    <Image
                        src={menu.image.downloadURL}
                        alt={'Menu item image'}
                        width={80}
                        height={40}
                        className={'rounded-2xl object-cover w-full h-full'}
                    />
                </div>
            )}
            <Label
                className={'ml-4 my-4 text-4xl'}
                dangerouslySetInnerHTML={{ __html: menuName }}
            />
        </a>
    );
};
