'use client';
import {FilterType, useAppDispatch, useAppSelector} from '../../../../store';
import {useClientTranslation} from '@repo/internationalization/client';
import {ComboBox, ComboBoxItem, Label} from '@repo/ui';
import {setAppliedFilters} from '../../../../store/app-state-slice';

export const WineLabelFilterComponent = () => {

    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const {appliedFilters, uniqueLabels} = useAppSelector(state => state.appState);
    const searchPlaceholder = t('menus.filterPanel.labels.placeholder.label');
    const maxOptionsSelectedLabel = t('menus.filterPanel.labels.maxOptionsSelected.label', {maxOptions: 3});
    const panelTitle = t('menus.filterPanel.labels.title');

    const comboBoxItems = uniqueLabels?.map(label => {
        return {
            value: label.name,
            label: t(`wine_labels.${label.name}`)
        };
    });

    const onItemsSelected = (selectedItems: ComboBoxItem[]) => {
        dispatch(setAppliedFilters({
            ...appliedFilters,
            [FilterType.Label]: selectedItems
        }));
    };

    return <div className={'min-h-[400px] md:w-60'}>
        <div className={'flex flex-col space-y-2'}>
            <Label className={'text-lg md:text-xl font-bold text-buttonText'}>{panelTitle}</Label>
            <ComboBox
                className={'text-buttonText'}
                items={comboBoxItems}
                defaultValue={appliedFilters[FilterType.Label]}
                noOptionsMessage={maxOptionsSelectedLabel}
                placeholder={searchPlaceholder}
                onItemsSelected={onItemsSelected}
            />
        </div>
    </div>;
};