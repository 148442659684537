import {PriceDisplay} from '../price-display';
import {currencyMap} from '@repo/utils';
import {QuantityComponent} from './quantity-component';

export const ItemPriceComponent = (props: {
    price?: number,
    currency: string,
    offer?: number,
    quantity?: number,
    unit: string
}) => {
    const {price, currency, offer, quantity, unit} = props;
    return <div className={'flex flex-row space-x-2 justify-start md:justify-end'}>
        <PriceDisplay price={price} currency={currencyMap[currency] ?? ''}
                      offer={offer}
                      textClassName={'text-lg md:text-2xl font-bold'}/>
        {quantity ? <QuantityComponent quantity={quantity} unit={unit}/> : null}
    </div>;
};
