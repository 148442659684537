'use client';
import {Button, Sheet, SheetContent, Skeleton} from '@repo/ui';
import React, {useEffect, useState} from 'react';

// Import all your icon components
import {IconSearch} from '../../../assets/icons/icon-search';
import {IconFeatured} from '../../../assets/icons/icon-featured';
import {IconWineType} from '../../../assets/icons/icon-wine-type';
import {IconGrapes} from '../../../assets/icons/icon-grapes';
import {IconRegion} from '../../../assets/icons/icon-region';
import {IconMillesime} from '../../../assets/icons/icon-millesime';
import {IconPairing} from '../../../assets/icons/icon-pairing';
import {IconPrice} from '../../../assets/icons/icon-price';
import {IconWineAroma} from '../../../assets/icons/icon-wine-aroma';
import {IconWineStyle} from '../../../assets/icons/icon-wine-style';
import {IconLabel} from '../../../assets/icons/icon-label';
import {FilterType, useAppDispatch, useAppSelector} from '../../../store';
import {
    FoodCategoryAssociationFilterComponent,
    PriceFilterComponent,
    SearchFilterComponent,
    WineAromaFilterComponent,
    WineGrapesFilterComponent,
    WineLabelFilterComponent,
    WineMillesimeFilterComponent,
    WineRegionFilterComponent,
    WineStyleFilterComponent,
    WineTypeFilterComponent
} from './filters';
import {setAppliedFilters, setIsMobileFilterOpen} from '../../../store/app-state-slice';
import {useGetMenuTemplateQuery} from '../../../store/api/restaurant-menu-api';
import {ApiMenuTemplate} from '@repo/api/model';
import {FilterNavComponent} from './filter-nav-component';
import {Responsive} from '@repo/utils';
import {useClientTranslation} from '@repo/internationalization/client';
import {QueryStatus} from '@reduxjs/toolkit/query/react';

export const FilterNav = () => {
    const [activeFilter, setActiveFilter] = useState<string | null>(null);
    const {t} = useClientTranslation();
    const filterMenuButtonLabel = t('menus.filter.mobile_filter_menu.close_button.label');
    const dispatch = useAppDispatch();
    const {
        menu,
        menuTemplate,
        restaurant,
        appliedFilters,
        isMobileFilterPanelOpen
    } = useAppSelector(state => state.appState);

    const {refetch: refetchMenuTemplate, status} = useGetMenuTemplateQuery({
        restaurantSlug: restaurant?.slug!,
        menuTemplateId: menu?.menuTemplate?.id!,
    }, {skip: !menu?.menuTemplate || !restaurant});

    useEffect(() => {
        if (menu && menuTemplate && status === QueryStatus.fulfilled) {
            refetchMenuTemplate();
        }
    }, [menu?.menuTemplate?.name, menuTemplate?.id]);

    if (!menuTemplate) {
        return null;
    }

    const filterConfig = makeFilterConfig(menuTemplate);

    const onFilterClick = (filterName: string) => {
        setActiveFilter(filterName);
        // only handle filters without side panel
        switch (filterName) {
            case FilterType.Featured: {
                dispatch(setAppliedFilters({
                    ...appliedFilters,
                    [FilterType.Featured]: !appliedFilters[FilterType.Featured]
                }));
                setActiveFilter(null);
                break;
            }
            default: {
                // Do nothing, only handle filters without side panel
                break;
            }
        }
    };

    const onCloseFilterPanel = () => {
        setActiveFilter(null);
    };

    const FilterComponent = filterComponents[activeFilter ?? ''] || null;
    if (Responsive.isMobileView()) {
        return <Sheet open={isMobileFilterPanelOpen}>
            <SheetContent side={'left'} className={'bg-transparent'}>
                <div className={'flex flex-row w-full'}>
                    <div className={'flex-grow'}>
                        <FilterNavComponent
                            filterConfig={filterConfig}
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                            appliedFilters={appliedFilters}
                            onFilterClick={onFilterClick}
                            onCloseFilterPanel={onCloseFilterPanel}
                            FilterComponent={FilterComponent}/>
                    </div>
                    <Button
                        className={'self-start mt-18 mr-2'}
                        onClick={() => dispatch(setIsMobileFilterOpen(false))}
                    >
                        {filterMenuButtonLabel}
                    </Button>
                </div>
            </SheetContent>
        </Sheet>;
    }
    return <FilterNavComponent
        filterConfig={filterConfig}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        appliedFilters={appliedFilters}
        onFilterClick={onFilterClick}
        onCloseFilterPanel={onCloseFilterPanel}
        FilterComponent={FilterComponent}/>;
};

const filterComponents: { [name: string]: (() => JSX.Element) | null } = {
    [FilterType.Name]: SearchFilterComponent,
    [FilterType.Featured]: null, // this filter does not have a side panel
    [FilterType.WineType]: WineTypeFilterComponent,
    [FilterType.WineGrapes]: WineGrapesFilterComponent,
    [FilterType.Region]: WineRegionFilterComponent,
    [FilterType.Millesime]: WineMillesimeFilterComponent,
    [FilterType.FoodCategory]: FoodCategoryAssociationFilterComponent,
    [FilterType.Price]: PriceFilterComponent,
    [FilterType.WineStyle]: WineStyleFilterComponent,
    [FilterType.WineAroma]: WineAromaFilterComponent,
    [FilterType.Label]: WineLabelFilterComponent,
};

const makeFilterConfig = (template: ApiMenuTemplate) => [
    {
        name: FilterType.FoodCategory,
        isOn: template.foodCategoryFilter,
        icon: IconPairing,
        labelKey: `menus.filter.${FilterType.FoodCategory}`
    },
    {
        name: FilterType.Name,
        isOn: template.nameFilter,
        icon: IconSearch,
        labelKey: `menus.filter.${FilterType.Name}`
    },
    {
        name: FilterType.Featured,
        isOn: template.featuredFilter,
        icon: IconFeatured,
        labelKey: `menus.filter.${FilterType.Featured}`
    },
    {
        name: FilterType.WineType,
        isOn: template.wineTypeFilter,
        icon: IconWineType,
        labelKey: `menus.filter.${FilterType.WineType}`
    },
    {
        name: FilterType.WineGrapes,
        isOn: template.grapesFilter,
        icon: IconGrapes,
        labelKey: `menus.filter.${FilterType.WineGrapes}`
    },
    {
        name: FilterType.Region,
        isOn: template.regionFilter,
        icon: IconRegion,
        labelKey: `menus.filter.${FilterType.Region}`
    },
    {
        name: FilterType.Millesime,
        isOn: template.millesimeFilter,
        icon: IconMillesime,
        labelKey: `menus.filter.${FilterType.Millesime}`
    },
    {
        name: FilterType.Price,
        isOn: template.priceFilter,
        icon: IconPrice,
        labelKey: `menus.filter.${FilterType.Price}`
    },
    {
        name: FilterType.WineStyle,
        isOn: template.wineStyleFilter,
        icon: IconWineStyle,
        labelKey: `menus.filter.${FilterType.WineStyle}`
    },
    {
        name: FilterType.WineAroma,
        isOn: template.aromaFilter,
        icon: IconWineAroma,
        labelKey: `menus.filter.${FilterType.WineAroma}`
    },
    {
        name: FilterType.Label,
        isOn: template.labelFilter,
        icon: IconLabel,
        labelKey: `menus.filter.${FilterType.Label}`
    }
];

export const FilterNavSkeleton = () => {
    return (
        <div className={'flex flex-row top-16 rounded-br-3xl z-51 fixed min-h-full'}>
            <div className={'flex flex-col bg-button pt-17 px-1 w-full'}>
                {/* Simulating multiple filters */}
                {Array.from({length: 5}).map((_, index) => (
                    <div
                        key={index}
                        className={`flex flex-col mt-1 w-18 h-18 p-2 rounded-lg justify-center items-center cursor-pointer`}
                    >
                        <Skeleton className={'bg-background/40 w-10 h-10 rounded-full'}/>
                        <Skeleton className={'bg-background/40 mt-2 h-4 w-3/4 rounded'}/>
                    </div>
                ))}
            </div>
        </div>
    );
};
