import * as React from 'react';
import {SVGProps} from 'react';

export const IconMillesime = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        {...props}
    >
        <path
            d="M12.32 23.26c-.1 7.39-1.39 17.6-6.89 24.6a3.25 3.25 0 0 0 2.53 5.28h4.053v3.604A3.26 3.26 0 0 0 15.269 60h39.947a3.26 3.26 0 0 0 3.256-3.256s.138-32.314.148-33.484zm9.21 11.18A2.052 2.052 0 0 1 23.51 33h5.63l1.81-5.41a2.079 2.079 0 0 1 3.95 0l1.74 5.36 5.71.05a2.081 2.081 0 0 1 1.22 3.76l-4.56 3.31 1.71 5.45a2.035 2.035 0 0 1-.75 2.32 2.06 2.06 0 0 1-2.44 0l-4.56-3.31-4.64 3.31a2.077 2.077 0 0 1-3.2-2.32l1.74-5.36-4.58-3.4a2.04 2.04 0 0 1-.76-2.32zm34.942 22.304A1.257 1.257 0 0 1 55.216 58H15.269a1.257 1.257 0 0 1-1.256-1.256V53.14H45.4a4.373 4.373 0 0 0 3.21-1.4 36.464 36.464 0 0 0 7.86-14.21l.002-.006zM12.32 21.26h46.3c-.03-1.64-.12-3.35-.29-5.12a3.253 3.253 0 0 0-3.24-2.98H50.4s0-5.09-.01-5.16a4.176 4.176 0 0 0-3.15-3.86A3.578 3.578 0 0 0 46.2 4 4.206 4.206 0 0 0 42 8.2v4.96H26.94s0-5.14-.01-5.23a4.185 4.185 0 0 0-3.14-3.79A4.088 4.088 0 0 0 22.74 4a4.206 4.206 0 0 0-4.2 4.2v4.96h-3.19a3.254 3.254 0 0 0-3.25 3.25c0 .08.2 3.04.22 4.85zM44.01 7.97A2.209 2.209 0 0 1 46.2 6a2.118 2.118 0 0 1 1.02.26A2.186 2.186 0 0 1 48.4 8.2v4.96H46v4.87a1 1 0 0 1-2 0s0-9.98.01-10.06zM20.57 7.9A2.191 2.191 0 0 1 22.74 6a2.129 2.129 0 0 1 1.03.26 2.19 2.19 0 0 1 1.17 1.94v4.96h-2.39v4.87a1 1 0 0 1-2 0s0-10.03.02-10.13z"/>
        <path
            d="m28.77 40.77-1.74 5.36.71.9-.59-.81 4.56-3.31a2.071 2.071 0 0 1 2.44 0l4.56 3.31.11-.09-1.74-5.35a2.099 2.099 0 0 1 .75-2.33l4.56-3.31-.04-.14h-5.63a2.065 2.065 0 0 1-1.98-1.43L33 28.21c-.01-.03-.02-.05-.07-.05l-1.82 5.41A2.062 2.062 0 0 1 29.14 35h-5.63l-.05.14 4.56 3.31a2.074 2.074 0 0 1 .75 2.32z"/>
    </svg>
);
