'use client';
import { ApiMenuItemType, ApiMenuSection } from '@repo/api/model';
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
    Label,
    useClientTranslatableText
} from '@repo/ui';
import { memo } from 'react';
import { Blockquote } from "../blockquote";
import { renderRow } from './render-row';
import { SubsectionCard } from './subsection-card';

export interface WineCategoryCardProps {
    scrollRef?: any;
    section: ApiMenuSection;
}

export const MenuSectionCard = (props: WineCategoryCardProps) => {
    const { section, scrollRef } = props;
    const description = useClientTranslatableText(section.description);
    const name = useClientTranslatableText(section.name);

    return <AccordionItem
        id={'wine_category_card_accordion_item'}
        value={section.id}
        key={section.id}
        className={'flex flex-col'}
        ref={scrollRef}
    >
        <AccordionTrigger
            id={'accordion_trigger'}
            className={'md:pr-20'}
            Subtitle={description ? <Blockquote>{description}</Blockquote> : null}
        >
            <h2>
                <Label className={'text-lg md:text-2xl font-bold mr-2 text-button/80'}>
                    {name}
                </Label>
            </h2>
        </AccordionTrigger>
        <AccordionContent id={'accordion_content'} className={'pr-1'}>
            {section.items?.map((item, itemIndex) => {
                if (item.type === ApiMenuItemType.SUB_ITEM) {
                    if (item.items?.length === 0) return null;
                    return <SubsectionCard
                        key={item.id + itemIndex}
                        item={item}
                    />;
                }
                return renderRow(item);
            })}
        </AccordionContent>
    </AccordionItem>;
};
