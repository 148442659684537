import * as React from 'react';
import {SVGProps} from 'react';

export const IconQuantity = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.54512 12.5358L2.91427 11.9192C2.24846 11.2685 1.1651 11.2685 0.499327 11.9192C-0.166442 12.5699 -0.166442 13.6287 0.499327 14.2794H0.499366C0.832271 14.6048 1.26948 14.7675 1.7068 14.7675C2.14404 14.7675 2.5814 14.6048 2.91427 14.2794L3.52644 13.6811L8.76525 18.8013L10.0732 17.523C10.1789 17.4197 10.2872 17.3195 10.3976 17.2221L4.57391 11.5303L3.54512 12.5358ZM2.0856 13.4695C1.87673 13.6737 1.53683 13.6736 1.32799 13.4695C1.11916 13.2654 1.11916 12.9332 1.32799 12.7291C1.53683 12.525 1.87669 12.5249 2.08556 12.7291L2.46437 13.0993L2.0856 13.4695Z"
            fill="currentColor"
        />
        <path
            d="M19.6463 10.3679C19.2205 9.95183 18.5277 9.95187 18.102 10.3679L16.6518 11.7853L15.8232 10.9754L18.6971 8.16655C19.5371 7.3455 19.9998 6.25386 19.9998 5.09275C19.9998 3.93163 19.5372 2.83999 18.6971 2.01894C17.857 1.19793 16.7401 0.745728 15.5521 0.745728C14.364 0.745728 13.2471 1.19789 12.4071 2.01894L8.78403 5.55995C8.2201 6.11115 7.75332 6.74409 7.39666 7.44125L6.65839 8.88433C6.31947 9.54686 5.89449 10.1592 5.39209 10.7104L11.3263 16.5103C12.2666 15.8868 13.3268 15.451 14.4563 15.2302C15.8949 14.949 17.2036 14.2645 18.2409 13.2507L19.6462 11.8772C19.8524 11.6756 19.966 11.4076 19.966 11.1226C19.966 10.8375 19.8525 10.5695 19.6463 10.3679ZM16.3669 2.96965L17.1956 3.77956L16.3669 4.58946L15.5382 3.77956L16.3669 2.96965ZM13.6738 3.7796L14.5024 4.5895L13.6737 5.3994L12.8451 4.5895L13.6738 3.7796ZM9.32319 11.4737L8.49452 10.6638L9.32319 9.8539L10.1519 10.6638L9.32319 11.4737ZM9.32319 8.03162L10.1519 7.22168L10.9805 8.03158L10.1519 8.84153L9.32319 8.03162ZM11.1877 13.2961L10.3591 12.4862L11.1877 11.6763L12.0164 12.4862L11.1877 13.2961ZM12.0164 10.6638L11.1877 9.85394L12.0164 9.04403L12.8451 9.85394L12.0164 10.6638ZM12.0164 7.22172L12.8451 6.41181L13.6737 7.22172L12.8451 8.03162L12.0164 7.22172ZM13.8809 12.4862L13.0522 11.6763L13.8809 10.8663L14.7096 11.6762L13.8809 12.4862ZM14.7096 9.8539L13.8809 9.04399L14.7096 8.23409L15.5382 9.04399L14.7096 9.8539ZM14.7096 6.41178L15.5382 5.60187L16.3669 6.41178L15.5382 7.22168L14.7096 6.41178Z"
            fill="currentColor"
        />
    </svg>
);
