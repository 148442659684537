import * as React from 'react';
import {SVGProps} from 'react';

export const IconShare = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={'0 0 40 40'}
        {...props}
    >
        <path
            d="M31.719 25.938c-2.316 0-4.357 1.139-5.638 2.871l-11.082-5.674c.184-.627.313-1.277.313-1.963 0-.93-.19-1.815-.52-2.628l11.598-6.979c1.29 1.515 3.187 2.498 5.329 2.498A7.039 7.039 0 0 0 38.75 7.03 7.039 7.039 0 0 0 31.719 0a7.039 7.039 0 0 0-7.032 7.031c0 .894.184 1.741.49 2.528l-11.632 7c-1.29-1.47-3.16-2.418-5.264-2.418a7.039 7.039 0 0 0-7.031 7.03 7.039 7.039 0 0 0 7.031 7.032c2.354 0 4.43-1.172 5.706-2.953l11.045 5.655c-.203.657-.345 1.341-.345 2.064A7.039 7.039 0 0 0 31.72 40a7.039 7.039 0 0 0 7.031-7.031 7.039 7.039 0 0 0-7.031-7.032Z"
        />
    </svg>
);
