import * as React from 'react';
import {SVGProps} from 'react';

export const IconWineStyle = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 500 500"
         {...props}>
        <path
            d="M325.188 0H271v91h76.721c-7.656-44.795-15.868-70.994-15.959-71.269zM41.034 151h429.932a513.854 513.854 0 0 0-6.702-30H47.736a514.221 514.221 0 0 0-6.702 30zM186.804 0l-6.581 19.746c-.079.262-8.289 26.459-15.945 71.254H241V0zM186.812 512H241v-91h-76.721c7.656 44.795 15.868 70.994 15.959 71.269zM470.966 361H41.034a513.854 513.854 0 0 0 6.702 30h416.528a514.221 514.221 0 0 0 6.702-30zM325.196 512l6.581-19.746c.079-.262 8.289-26.459 15.945-71.254H271v91zM358.197 181H271v150h87.197c1.732-24.56 2.803-49.633 2.803-75s-1.071-50.44-2.803-75zM455.655 91c-9.232-28.445-19.292-51.859-27.228-67.021C420.648 9.185 405.399 0 388.598 0h-31.793l3.419 10.254c.419 1.258 9.619 30.487 17.888 80.746zM155.193 0h-31.791C106.6 0 91.351 9.185 83.573 23.994 75.637 39.15 65.578 62.558 56.345 91h77.543c8.269-50.259 17.468-79.488 17.888-80.746zM155.193 512l-3.417-10.254c-.419-1.258-9.619-30.487-17.888-80.746H56.345c9.232 28.445 19.292 51.859 27.228 67.021C91.352 502.815 106.601 512 123.402 512zM378.113 421c-8.267 50.259-17.457 79.488-17.889 80.746L356.807 512h31.791c16.802 0 32.051-9.185 39.829-23.994 7.936-15.156 17.996-38.564 27.228-67.006zM241 181h-87.197c-1.732 24.56-2.803 49.633-2.803 75s1.071 50.44 2.803 75H241zM31 256c0 26.995 1.959 51.932 5.056 75h87.706c-1.688-24.598-2.761-49.656-2.761-75s1.073-50.402 2.761-75H36.056C32.959 204.068 31 229.005 31 256zM481 256c0-26.995-1.959-51.932-5.056-75h-87.706c1.688 24.598 2.761 49.656 2.761 75s-1.073 50.402-2.761 75h87.706c3.097-23.068 5.056-48.005 5.056-75z"/>
    </svg>
);
