import * as React from 'react';
import {SVGProps} from 'react';

export const IconPrice = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 486 486"
        {...props}
    >
        <path
            d="M243 0C108.8 0 0 108.8 0 243s108.8 243 243 243 243-108.8 243-243S377.2 0 243 0zm84.8 366.1c-7.3 3.3-14.9 6.1-22.7 8.2-29.6 8.2-59.2 8.5-88.7-1.1-35.6-11.5-59.2-35.8-73.3-69.8-2.7-6.6-4.5-13.5-6.6-20.4h-27.9c-4.7 0-8.4-3.8-8.4-8.4v-18.8c0-4.7 3.8-8.4 8.4-8.4h23.1v-15.3h-23.1c-4.7 0-8.4-3.8-8.4-8.4v-18.8c0-4.7 3.8-8.4 8.4-8.4H138l1-2.9c7.9-25.1 21.5-46.6 42.4-62.8 16.6-12.9 35.8-20 56.4-23.3 29.3-4.7 57.7-.7 85.2 10.1 1.8.7 3.5 1.5 5.2 2.5 6.6 3.9 8.3 8.8 5.4 15.9-3.1 7.4-6.2 14.9-9.6 22.2-2.9 6.3-6.9 8.3-13.7 6.8-9.3-2.1-18.4-5-27.7-6.9-19.6-4-39.1-4.2-58.2 3.3-16.4 6.5-26.4 19-33.2 34.6-.1.2-.1.4-.1.6h57.1c4.7 0 8.4 3.8 8.4 8.4v18.8c0 4.7-3.8 8.4-8.4 8.4H183c-.1 5 0 10 0 15.3h65.3c4.7 0 8.4 3.8 8.4 8.4v18.8c0 4.7-3.8 8.4-8.4 8.4h-59.6c7.2 20.6 18.9 36.5 40.9 43.4 17.6 5.5 35.3 5.2 53.1 1.5 9.2-1.9 18.2-4.7 27.4-6.8 7.2-1.6 11.1.3 14.1 6.9 3.4 7.4 6.6 14.9 9.6 22.5 2.8 6.8 1 12.3-6 15.5z"/>
    </svg>
);
