'use client';
import {ClientTranslatableText, Label, useClientTranslatableText} from '@repo/ui';
import {useAppDispatch, useAppSelector} from '../../store';
import {Link} from 'react-router-dom';
import {setSelectedItemId} from '../../store/app-state-slice';
import {memo} from 'react';
import {ApiMenuItem} from '@repo/api/model';
import {useClientTranslation} from '@repo/internationalization/client';
import {MedalsComponent} from './medals-component';
import {MenuItemTitleComponent} from './menu-item-title-component';
import {ItemPriceComponent} from './item-price-component';

const shouldDisplayGlassPrice = (menuItem: ApiMenuItem, isBottleGlassFilterOn: boolean, byGlass: boolean) => {
    const isValidPrice =
        menuItem.isVisibleForPricePerGlass &&
        menuItem.pricePerGlass !== undefined
        && menuItem.pricePerGlass !== 0;

    if (isBottleGlassFilterOn && !byGlass) {
        return false;
    }
    return isValidPrice;
};

const shouldDisplayBottlePrice = (menuItem: ApiMenuItem, isBottleGlassFilterOn: boolean, byGlass: boolean) => {
    const isValidPrice =
        menuItem.isVisibleForPrice &&
        menuItem.price !== undefined
        && menuItem.price !== 0;
    if (isBottleGlassFilterOn && byGlass) {
        return false;
    }
    return isValidPrice;
};

export const WineItemRow = memo((props: {
    item: ApiMenuItem;
}) => {
    const {item} = props;

    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const pairingInfoLabel = t('menus.pairing_info.label');
    const viewDetailsLabel = t('menus.item_list.view_details');
    const byGlass = useAppSelector(state => state.appState.appliedFilters.byGlass) as boolean;
    const isBottleGlassFilterOn = useAppSelector(state => state.appState.menuTemplate?.bottleGlassFilter) ?? false;
    const currency = useAppSelector(state => state.appState.currency);

    const foodCategoryAssociation = item.foodCategoryAssociation?.map((association) => t(`Food_Category_Association.${association.name}`)) ?? [];

    const name = useClientTranslatableText(item.name);
    const publishName = item.publishName ? useClientTranslatableText(item.publishName) : '';
    const maxNameLength = publishName.length > 0 ? publishName.length : name.length;

    return <Link
        onClick={() => {
            window.scrollTo(0, 0);
            dispatch(setSelectedItemId(item.id));
        }}
        id={item.id}
        to={`${item.id}`}
        key={item.id}
        className={'flex flex-col my-2'}
    >
        <div className={'flex flex-col md:flex-row justify-between'}>
            <div className={'flex flex-row items-center'}>
                <MedalsComponent item={item} orientation={maxNameLength > 40 ? 'vertical' : 'horizontal'}/>
                <MenuItemTitleComponent item={item}/>
            </div>
            <div className={'flex flex-col'}>
                <div id="bottlePrice" className={'ml-0 md:ml-4 flex flex-col'}>
                    {shouldDisplayBottlePrice(item, isBottleGlassFilterOn, byGlass) ?
                        <ItemPriceComponent
                            price={item.price}
                            currency={currency}
                            offer={item.priceOffer}
                            quantity={item.quantity}
                            unit={item.quantityUnit}/>
                        : null
                    }
                </div>
                <div id="glassPrice" className={'ml-0 md:ml-4 flex flex-col'}>
                    {shouldDisplayGlassPrice(item, isBottleGlassFilterOn, byGlass) &&
                        <ItemPriceComponent
                            price={item.pricePerGlass}
                            currency={currency}
                            offer={item.pricePerGlassOffer}
                            quantity={item.quantityPerGlass!}
                            unit={item.quantityUnit}/>
                    }
                </div>
            </div>
        </div>
        <ClientTranslatableText className={'text-base md:text-xl mt-4'} translatables={item.shortDescription}/>
        {foodCategoryAssociation && foodCategoryAssociation.length > 0 &&
            <div className={'flex flex-row flex-wrap '}>
                <Label className={'italic me-1 mt-0.5 text-base md:text-lg'}>{pairingInfoLabel}:&nbsp;</Label>
                {foodCategoryAssociation.map((association, index, array) => {
                    return <Label key={association} className={'italic me-1 mt-0.5 text-base md:text-lg'}>
                        {association}{index < array.length - 1 ? ', ' : ''}
                    </Label>;
                })}
            </div>}
        <div className={'flex w-full justify-end underline text-button text-lg'}>{viewDetailsLabel}</div>
    </Link>;
});
