import * as React from 'react';
import {SVGProps} from 'react';

export const IconWeight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.8906 7.15445C15.8287 6.88296 15.6739 6.64024 15.4517 6.46642C15.2296 6.29259 14.9533 6.19807 14.6688 6.19847H12.1875V4.87292H7.8125V6.19847H5.33125C5.04666 6.19807 4.77045 6.29259 4.54828 6.46642C4.32611 6.64024 4.17128 6.88296 4.10938 7.15445L2.3625 15.1963C2.32809 15.3725 2.33379 15.5539 2.3792 15.7277C2.42461 15.9014 2.50861 16.0633 2.62524 16.2018C2.74187 16.3403 2.88827 16.4519 3.05403 16.5289C3.2198 16.6059 3.40087 16.6462 3.58438 16.647H16.4156C16.5991 16.6462 16.7802 16.6059 16.946 16.5289C17.1117 16.4519 17.2581 16.3403 17.3748 16.2018C17.4914 16.0633 17.5754 15.9014 17.6208 15.7277C17.6662 15.5539 17.6719 15.3725 17.6375 15.1963L15.8906 7.15445ZM10 14.732C9.55186 14.7353 9.10746 14.6522 8.69218 14.4875C8.27689 14.3229 7.89886 14.0799 7.57965 13.7725C7.26045 13.465 7.00633 13.0991 6.83181 12.6957C6.65728 12.2923 6.56577 11.8592 6.5625 11.4212C6.75 7.04449 13.25 7.04449 13.4375 11.4212C13.4342 11.8592 13.3427 12.2923 13.1682 12.6957C12.9937 13.0991 12.7396 13.465 12.4204 13.7725C12.1012 14.0799 11.7231 14.3229 11.3078 14.4875C10.8925 14.6522 10.4481 14.7353 10 14.732Z"
            fill="currentColor"
        />
        <path
            d="M17.7126 1.55902C17.6416 1.46236 17.5476 1.38404 17.4387 1.33082C17.3298 1.27761 17.2093 1.25111 17.0876 1.2536H2.91257C2.80713 1.24256 2.70052 1.25702 2.60215 1.29571C2.50377 1.33441 2.41663 1.39615 2.34841 1.4755C2.28019 1.55485 2.23297 1.64937 2.21092 1.75075C2.18887 1.85213 2.19265 1.95725 2.22195 2.05687L2.75007 3.4099C2.88173 3.68022 3.09317 3.90583 3.35716 4.0577C3.62114 4.20957 3.9256 4.28073 4.23132 4.26203H15.7688C16.0745 4.28073 16.379 4.20957 16.643 4.0577C16.907 3.90583 17.1184 3.68022 17.2501 3.4099L17.7782 2.05687C17.8095 1.97493 17.8199 1.88675 17.8085 1.8C17.7971 1.71324 17.7641 1.63052 17.7126 1.55902Z"
            fill="currentColor"
        />
        <path
            d="M10 8.73354C9.2626 8.7262 8.55233 9.00508 8.02506 9.50896C7.49778 10.0128 7.19657 10.7006 7.1875 11.4213C7.33437 14.9856 12.6656 14.9856 12.8125 11.4213C12.8034 10.7006 12.5022 10.0128 11.9749 9.50896C11.4477 9.00508 10.7374 8.7262 10 8.73354Z"
            fill="currentColor"
        />
        <path
            d="M4.0625 17.2579V17.5145C4.0625 17.7575 4.16127 17.9906 4.33709 18.1624C4.5129 18.3342 4.75136 18.4308 5 18.4308H7.5C7.74864 18.4308 7.9871 18.3342 8.16291 18.1624C8.33873 17.9906 8.4375 17.7575 8.4375 17.5145V17.2579H4.0625Z"
            fill="currentColor"
        />
        <path
            d="M11.5625 17.2579V17.5145C11.5625 17.7575 11.6613 17.9906 11.8371 18.1624C12.0129 18.3342 12.2514 18.4308 12.5 18.4308H15C15.2486 18.4308 15.4871 18.3342 15.6629 18.1624C15.8387 17.9906 15.9375 17.7575 15.9375 17.5145V17.2579H11.5625Z"
            fill="currentColor"
        />
    </svg>
);
