import {FilterType, useAppDispatch, useAppSelector} from '../../../../store';
import {useClientTranslation} from '@repo/internationalization/client';
import {ComboBox, ComboBoxItem, Label} from '@repo/ui';
import {setAppliedFilters} from '../../../../store/app-state-slice';

export const WineMillesimeFilterComponent = () => {

    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const {appliedFilters, uniqueMillesime} = useAppSelector(state => state.appState);
    const searchPlaceholder = t('menus.filterPanel.wineMillesime.placeholder.label');
    const maxOptionsSelectedLabel = t('menus.filterPanel.wineMillesime.maxOptionsSelected.label', {maxOptions: 3});
    const panelTitle = t('menus.filterPanel.labels.title');

    const comboBoxItems = uniqueMillesime?.map(millesime => {
        return {
            value: String(millesime.year),
            label: String(millesime.year)
        };
    });

    const onItemsSelected = (selectedItems: ComboBoxItem[]) => {
        dispatch(setAppliedFilters({
            ...appliedFilters,
            [FilterType.Millesime]: selectedItems
        }));
    };

    return <div className={'min-h-[400px]'}>
        <div className={'flex flex-col w-60 space-y-2'}>
            <Label className={'text-xl font-bold text-buttonText'}>{panelTitle}</Label>
            <ComboBox
                className={'text-buttonText'}
                items={comboBoxItems}
                defaultValue={appliedFilters[FilterType.Millesime]}
                noOptionsMessage={maxOptionsSelectedLabel}
                placeholder={searchPlaceholder}
                onItemsSelected={onItemsSelected}
            />
        </div>
    </div>;
};