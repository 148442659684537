import * as React from 'react';
import { SVGProps } from 'react';

export const IconAllergens = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_504_2591)">
            <path d="M6.0415 5.33508C6.16958 6.08497 6.52918 6.77207 7.10126 7.35211C7.97981 7.65497 8.79888 8.2276 9.40156 8.95787C9.28398 7.1215 7.86735 5.62402 6.0415 5.33508Z" fill="currentColor" />
            <path d="M6.04102 11.099C6.3064 12.7282 7.65134 14.0739 9.35807 14.341C9.08338 12.6813 7.73914 11.3675 6.04102 11.099Z" fill="currentColor" />
            <path d="M10.6416 14.342C12.3409 14.074 13.686 12.7593 13.9603 11.0985C12.275 11.3625 10.9123 12.6795 10.6416 14.342Z" fill="currentColor" />
            <path d="M11.7712 9.40466C11.1349 10.043 10.7378 10.8478 10.6245 11.7112C11.2968 10.9284 12.2058 10.3462 13.243 10.0709C13.6047 9.57159 13.8601 8.92707 13.9623 8.27026C13.1401 8.40423 12.3785 8.79543 11.7712 9.40466Z" fill="currentColor" />
            <path d="M8.22938 9.40471C7.62287 8.79629 6.86245 8.40558 6.0415 8.27112C6.14719 8.92299 6.40811 9.56935 6.77852 10.0766C7.80697 10.3541 8.70833 10.9337 9.37609 11.7112C9.26281 10.8479 8.86568 10.043 8.22938 9.40471Z" fill="currentColor" />
            <path d="M4.80755 10.4713C4.80755 10.1789 5.03027 9.9379 5.31882 9.9C4.99099 9.19715 4.80755 8.40768 4.80755 7.64157C4.80672 7.31401 5.08854 7.05089 5.42302 7.06481C5.01748 6.34789 4.80755 5.55122 4.80755 4.70741C4.80755 4.38868 5.07191 4.1303 5.39802 4.1303C6.38504 4.1303 7.30858 4.40092 8.09518 4.87014C8.22423 3.73153 8.74274 2.65007 9.58267 1.82916C9.81322 1.60378 10.1871 1.60378 10.4177 1.82916C11.2543 2.64682 11.772 3.72924 11.9023 4.87186C12.7166 4.40046 13.7823 4.0402 14.7948 4.16228L16.394 2.48726C12.5936 -0.719737 6.40832 -0.450039 2.91788 3.10077C-0.902 6.6834 -0.977521 13.1093 2.75001 16.7764L5.83811 13.5027C5.19106 12.6563 4.80755 11.6067 4.80755 10.4713Z" fill="currentColor" />
            <path d="M10.7356 5.82162C10.8298 4.85978 10.5628 3.89698 9.99955 3.12671C9.4322 3.90016 9.16518 4.86299 9.26375 5.82112C9.55077 6.134 9.79862 6.48175 9.9999 6.85704C10.2011 6.48198 10.4488 6.13438 10.7356 5.82162Z" fill="currentColor" />
            <path d="M17.2566 3.27527L15.1291 5.50368C15.0401 6.05462 14.858 6.57841 14.5868 7.06465C14.9181 7.05585 15.1931 7.31759 15.1926 7.64156C15.1926 8.41428 15.0133 9.20383 14.6927 9.90171C14.9757 9.94439 15.1926 10.1828 15.1926 10.4713C15.1926 13.0746 13.1766 15.2259 10.5904 15.5133V17.9808C10.5904 18.2995 10.3261 18.5579 10 18.5579C9.67394 18.5579 9.40954 18.2995 9.40954 17.9808V15.5133C8.36461 15.3972 7.41289 14.9768 6.6495 14.3452L3.61328 17.5638C7.41406 20.765 13.5941 20.4928 17.0822 16.9444C20.8996 13.3641 20.978 6.94322 17.2566 3.27527Z" fill="currentColor" />
            <path d="M12.9277 7.34217C13.4849 6.76687 13.8359 6.08314 13.9606 5.33447C12.1343 5.62276 10.7177 7.12055 10.5996 8.95695C11.2088 8.21903 12.038 7.643 12.9277 7.34217Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_504_2591">
                <rect width="20" height="19.5472" fill="currentColor" transform="translate(0 0.249023)" />
            </clipPath>
        </defs>
    </svg>
);
