import {cn, Label} from '@repo/ui';

export const PriceDisplay = ({price, offer, currency, textClassName}: {
    price?: number,
    offer?: number,
    currency: string,
    textClassName?: string
}) => {
    return <div className={'flex flex-row justify-end'}>
        {price ? <Label className={textClassName}>{offer ? offer : price}&nbsp;{currency}</Label> : null}
        {(offer && price) ? <Label className={cn('ml-2 line-through', textClassName)}>{price}&nbsp;{currency}</Label> : null}
    </div>;
};
