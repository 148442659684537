'use client';
import {cn, Skeleton} from '@repo/ui';
import {LanguageSelector} from '../language';
import {useAppSelector} from '../../store';
import React from 'react';
import {Link} from 'react-router-dom';

interface NavbarProps {
    className?: string;
    title: string;
}

export const Navbar = (props: NavbarProps) => {
    const {title, className} = props;
    const restaurant = useAppSelector(state => state.appState.restaurant);

    return (
        <div id={'navbar'} className={'h-15 flex flex-col sticky top-0 bg-button'} style={{zIndex: 1000}}>
            <div className={cn(className, 'flex justify-between items-center px-1 md:px-2 py-1 md:py-2')}>
                <div className="flex items-center justify-start w-1/4">
                    {restaurant?.logo && (
                        <Link to={'/'}>
                            <img src={restaurant.logo.downloadURL} alt="logo" width={120} height={46}/>
                        </Link>
                    )}
                </div>
                <div className="flex items-center justify-center w-2/4">
                    <h1 className="text-buttonText text-xl md:text-2xl font-bold uppercase"
                        dangerouslySetInnerHTML={{
                            __html: title
                        }}/>
                </div>
                <div className="flex items-center justify-end w-1/4 text-right z-52">
                    {restaurant?.supportedLanguages &&
                        <LanguageSelector/>
                    }
                </div>
            </div>
            {/*space*/}
            <div className="h-[1px] bg-background"/>
        </div>
    );
};

export const NavbarSkeleton = (props: Pick<NavbarProps, 'className'>) => {
    const {className} = props;
    return (
        <div className={'flex flex-col sticky top-0 h-15 bg-button w-full'} id="navbar" style={{ zIndex: 1000 }}>
            <div className={cn(className, 'flex justify-between items-center px-2 py-2')}>
                <div className="flex items-center justify-start w-1/4">
                    <Skeleton className={'bg-background/40 w-32 h-8 rounded-md'}/> {/* Adjusted to closely match the original image size */}
                </div>
                <div className="flex items-center justify-center w-2/4">
                    <Skeleton className="bg-background/40 text-2xl font-bold uppercase h-8 w-2/4"/> {/* Adjusted to simulate the title's potential size */}
                </div>
                <div className="flex items-center justify-end w-1/4 text-right z-50">
                    <Skeleton className="bg-background/40 w-44 h-8 rounded-xl"/> {/* Placeholder for the language selector */}
                </div>
            </div>
        </div>
    );
};

