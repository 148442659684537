'use client';
import {FilterType} from '../../../store';
import {useClientTranslation} from '@repo/internationalization/client';
import React, {useEffect, useRef} from 'react';
import {Filter} from './filter';
import {Button, cn} from '@repo/ui';
import {FilterComponentProps} from './filter-component-props';

export const FilterNavComponent = (props: FilterComponentProps) => {
    const {t} = useClientTranslation();
    const {
        filterConfig,
        activeFilter,
        setActiveFilter,
        appliedFilters,
        onFilterClick,
        onCloseFilterPanel,
        FilterComponent
    } = props;
    const outsideClickDetectorRef = useRef<any>(null); // Ref for the filter nav container
    const filterPanelCloseButtonLabel = t('menus.filterPanel.close');

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (outsideClickDetectorRef.current && !outsideClickDetectorRef.current.contains(event.target)) {
                setActiveFilter(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return <div id={'filer-nav'} className={'flex md:fixed flex-row top-14 rounded-br-3xl min-h-full'}>
        <div className={'flex flex-col bg-button pt-17 md:px-1'}>
            {filterConfig.filter(f => f.isOn).map((filter) => {
                const isActive = activeFilter === filter.name ||
                    (filter.name === FilterType.Name && Boolean(appliedFilters[filter.name])) ||
                    (filter.name === FilterType.Featured && appliedFilters[filter.name]) ||
                    (filter.name === FilterType.FoodCategory && appliedFilters[filter.name]?.length > 0);

                return <Filter
                    key={filter.name}
                    filterName={filter.name}
                    icon={filter.icon}
                    labelKey={filter.labelKey}
                    onClick={onFilterClick}
                    isActive={isActive}
                />;
            })}
        </div>
        {activeFilter && FilterComponent && <div
            className={cn(
                'ml-2 flex flex-col w-full mr-2 transform transition-transform mt-18',
                {'translate-x-full': !activeFilter, 'translate-x-0': activeFilter}
            )}
        >
            <div ref={outsideClickDetectorRef} className={'flex flex-col space-y-4 bg-button p-4 rounded-md'}>
                <FilterComponent/>
                <div className={'hidden md:block'}>
                    <Button variant={'outline'} className={'w-full text-lg md:text-xl'} onClick={onCloseFilterPanel}>
                        {filterPanelCloseButtonLabel}
                    </Button>
                </div>
            </div>

        </div>}
    </div>;
};