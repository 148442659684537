import * as React from 'react';
import {SVGProps} from 'react';

export const IconOffer = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 30"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M28.074 15.203a.857.857 0 0 1 0-.768l1.121-2.26c.625-1.26.13-2.76-1.126-3.418l-2.256-1.18a.87.87 0 0 1-.458-.62l-.441-2.48c-.246-1.38-1.542-2.308-2.95-2.111l-2.528.352a.89.89 0 0 1-.741-.237L16.859.73a2.638 2.638 0 0 0-3.645 0l-1.836 1.75a.89.89 0 0 1-.74.237l-2.53-.352c-1.407-.197-2.703.731-2.948 2.112l-.442 2.479a.87.87 0 0 1-.457.62l-2.256 1.18a2.555 2.555 0 0 0-1.127 3.417L2 14.436a.857.857 0 0 1 0 .767L.878 17.465a2.555 2.555 0 0 0 1.127 3.416l2.256 1.18a.87.87 0 0 1 .457.621l.442 2.48a2.599 2.599 0 0 0 2.573 2.137c.124 0 .25-.008.376-.026l2.529-.352a.89.89 0 0 1 .74.237l1.836 1.75c.511.487 1.167.73 1.823.73.655 0 1.311-.243 1.822-.73l1.836-1.75a.89.89 0 0 1 .74-.237l2.53.352c1.408.197 2.703-.731 2.949-2.111l.44-2.48a.87.87 0 0 1 .459-.62l2.256-1.18a2.555 2.555 0 0 0 1.126-3.417l-1.121-2.262ZM11.568 7.127c1.753 0 3.18 1.406 3.18 3.134s-1.427 3.134-3.18 3.134c-1.754 0-3.18-1.406-3.18-3.134s1.426-3.134 3.18-3.134ZM9.926 21.065a.871.871 0 0 1-.613.25.871.871 0 0 1-.613-.25.846.846 0 0 1 0-1.21l11.447-11.28a.876.876 0 0 1 1.227 0 .846.846 0 0 1 0 1.208L9.925 21.065Zm8.58 1.447c-1.754 0-3.18-1.406-3.18-3.134s1.426-3.134 3.18-3.134c1.753 0 3.18 1.406 3.18 3.134s-1.427 3.134-3.18 3.134Z"/>
            <path
                d="M18.506 17.953c-.797 0-1.446.64-1.446 1.425s.649 1.424 1.446 1.424c.797 0 1.445-.639 1.445-1.424 0-.786-.648-1.425-1.445-1.425ZM11.568 8.836c-.797 0-1.446.64-1.446 1.425s.649 1.425 1.446 1.425c.797 0 1.445-.64 1.445-1.425s-.648-1.425-1.445-1.425Z"/>
        </g>
        <defs>
            <clipPath id="a">
                <path d="M0 0h30.073v29.639H0z"/>
            </clipPath>
        </defs>
    </svg>
);
