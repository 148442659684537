import {Label} from '../ui';
import {TranslatableTextProps} from './translatable-text-props';
import {getCookies} from 'next-client-cookies/server';

/**
 * This component is used to display a translatable text on the server side.
 * To be used only with Translatable objects.
 */
export const ServerTranslatableText = (props: TranslatableTextProps) => {
    const {translatables, className} = props;
    if (!translatables || translatables.length === 0) {
        return null;
    }
    const cookies = getCookies();
    const lang = cookies.get('lang') ?? 'en';

    const matchedTranslatable = translatables.find(value => value.language.toLowerCase() === lang);
    const maybeHtml = matchedTranslatable?.value ?? (translatables[0]?.value ?? '');
    if (!maybeHtml) {
        return null;
    }

    return <Label suppressHydrationWarning={true} className={className} dangerouslySetInnerHTML={{__html: maybeHtml}}/>;
};

export const getServerTranslatableText = (translatables: {
    language: string;
    value: string;
}[], targetLang: string): string => {
    const matchedTranslatable = translatables.find(value => value.language.toLowerCase() === targetLang);
    return matchedTranslatable?.value ?? (translatables[0]?.value ?? '');
};