import * as React from 'react';
import {SVGProps} from 'react';

export const IconFeatured = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 500 500"
         {...props}>
        <path
            d="m200.123 122 58.166-56.394c7.121-6.944 11.549-16.638 11.549-27.37C269.837 17.119 252.719 0 231.602 0c-12.859 0-24.226 6.354-31.157 16.087h-.644C192.87 6.354 181.502 0 168.644 0c-21.117 0-38.235 17.119-38.235 38.235 0 10.732 4.428 20.426 11.549 27.37zM192.172 155.814H59.006l-9.551 58.848h152.269zM206.593 244.662H44.586l-7.435 45.811c-5.623 34.648 11.093 69.596 41.596 86.963l31.843 18.131V482H77.287v30h96.606v-30h-33.304v-86.433l31.843-18.131c30.504-17.368 47.22-52.315 41.597-86.963zm-83.522 77.053h-30v-28.84h30zM336.634 90.756 216.871 148.98l17.14 57.102 136.943-66.576zM462.811 392.105l-29.952 14.561-37.791-77.733 20.711-30.229c19.84-28.958 19.593-67.697-.614-96.397h.001l-26.717-37.949-145.701 70.834 13.343 44.45c10.091 33.619 40.404 57.741 75.432 60.024l36.565 2.384 37.791 77.734-29.951 14.561 13.117 26.98 86.884-42.239zM320.044 283.29l-12.421-25.548 26.98-13.117 12.421 25.548z"/>
    </svg>
);
