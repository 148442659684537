'use client';
import {ClientTranslatableText, Label, useClientTranslatableText} from '@repo/ui';
import {useAppDispatch, useAppSelector} from '../../store';
import {Link} from 'react-router-dom';
import {setSelectedItemId} from '../../store/app-state-slice';
import {memo} from 'react';
import {ApiMenuItem} from '@repo/api/model';
import {useClientTranslation} from '@repo/internationalization/client';
import {MedalsComponent} from './medals-component';
import {MenuItemTitleComponent} from './menu-item-title-component';
import {ItemPriceComponent} from './item-price-component';
import {LogIf} from '@repo/utils';

const shouldDisplayPrice = (menuItem: ApiMenuItem) => {
    return menuItem.isVisibleForPrice
        && menuItem.price !== undefined
        && menuItem.price !== 0;
};

export const FoodItemRow = memo((props: {
    item: ApiMenuItem;
}) => {
    const {item} = props;

    const dispatch = useAppDispatch();
    const currency = useAppSelector(state => state.appState.currency);
    const {t} = useClientTranslation();
    const viewDetailsLabel = t('menus.item_list.view_details');

    const name = useClientTranslatableText(item.name);
    const publishName = item.publishName ? useClientTranslatableText(item.publishName) : '';
    const maxNameLength = publishName.length > 0 ? publishName.length : name.length;

    const ingredients = item.ingredients?.map(ingredient => useClientTranslatableText(ingredient.name)).join(', ');

    LogIf(item.id === '0FF3ysQnqf7KaCVvKJco', item);

    return <Link
        onClick={() => {
            window.scrollTo(0, 0);
            dispatch(setSelectedItemId(item.id));
        }}
        id={item.id}
        to={`${item.id}`}
        key={item.id}
        className={'flex flex-col my-2'}
    >
        <div className={'flex flex-col md:flex-row justify-between'}>
            <div className={'flex flex-row items-center'}>
                <MedalsComponent item={item} orientation={maxNameLength > 35 ? 'vertical' : 'horizontal'}/>
                <MenuItemTitleComponent item={item}/>
            </div>
            <div className={'flex flex-col'}>
                <div id="bottlePrice" className={'ml-0 md:ml-4 flex flex-col'}>
                    {shouldDisplayPrice(item) ?
                        <ItemPriceComponent
                            price={item.price}
                            currency={currency}
                            offer={item.priceOffer}
                            quantity={item.quantity}
                            unit={item.quantityUnit}/>
                        : null
                    }
                </div>
            </div>
        </div>
        {item.id === 'SZHbaCRLqVRgzH1h9hfS' ?
            <ClientTranslatableText
                className={'text-base md:text-xl mt-4'}
                translatables={item.shortDescription}/>
            : null
        }
        <Label className={'text-base md:text-xl mt-4'}>{ingredients}</Label>
        <div className={'flex w-full justify-end underline text-button text-lg'}>{viewDetailsLabel}</div>
    </Link>;
});
