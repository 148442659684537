'use client';

import Image from 'next/image';
import React from 'react';
import { ApiMenuItem } from '@repo/api/model';
import { useClientTranslation } from '@repo/internationalization/client';
import { QuantityFormatter, Responsive } from '@repo/utils';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
    Badge,
    Checkbox,
    ClientTranslatableText,
    cn,
    Input,
    Label,
    useClientTranslatableText
} from '@repo/ui';
import { useAddSubscriberMutation } from '../../store/api/restautant-api';
import { Link } from 'react-router-dom';
import { IconBack } from '../../assets/icons/icon-back';
import { IconShare } from '../../assets/icons/icon-share';
import { IconWineType } from '../../assets/icons/icon-wine-type';
import { IconMillesime } from '../../assets/icons/icon-millesime';
import { IconGrapes } from '../../assets/icons/icon-grapes';
import { IconWineStyle } from '../../assets/icons/icon-wine-style';
import { IconWineAroma } from '../../assets/icons/icon-wine-aroma';
import { IconPairing } from '../../assets/icons/icon-pairing';
import { IconLabel } from '../../assets/icons/icon-label';
import { IconThermometer } from '../../assets/icons/icon-thermometer';
import { IconPrice } from '../../assets/icons/icon-price';
import { PriceLineComponent } from './price-line-component';
import { MedalsComponent } from '../menu';
import { IconWeight } from '../../assets/icons/icon-weight';

const ICON_SIZE = 30;
const LINE_LABEL_CLASS_NAME = 'text-lg md:text-2xl text-foregroundAccent';
const PROPERTY_ICON_CLASS_NAME = 'fill-foregroundAccent w-6 md:w-6 h-8 md:h-8';
const PROPERTY_ICON_WRAPPER_CLASS_NAME = 'mr-2 md:mr-4 items-top';
const PROPERTY_DESCRIPTION_CLASS_NAME = 'w-3/4 lg:w-5/6';

export interface WineDetailsCardProps {
    menuId: string;
    restaurantSlug: string;
    item: ApiMenuItem;
    currency: string;
    style?: any;
    isClient?: boolean;
}

const isValidEmailAddress = (emailAddress: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
};

export const WineDetailsCard = (props: WineDetailsCardProps) => {

    const { item, menuId, restaurantSlug, currency, style, isClient } = props;

    return <div className={'overflow-y-scroll no-scrollbar'} style={style}>
        <TopPart
            menuId={menuId}
            restaurantSlug={restaurantSlug}
            menuItem={item}
            currency={currency}
            isClient={isClient}
        />
        {/* Bottom Part */}
        <div id="bottomPart" className="mt-8 pl-4 md:pl-20">
            {/* Image that overlaps top and bottom parts */}
            {item.image && (
                <div className="float-right mt-[-350px] md:mt-[-340px]">
                    <Image
                        src={item.image.downloadURL}
                        alt=""
                        priority
                        className="object-contain h-[650px] md:h-[700px] w-[162px] md:w-[200px]"
                        width={200} // Adjust the width to match your actual image's aspect ratio
                        height={700} // Adjust the height to match your actual image's aspect ratio
                    />
                </div>
            )}
            {/* Description */}
            <ClientTranslatableText
                className={'text-lg md:text-2xl'}
                translatables={item.longDescription}
            />
            {/* Additional content here will wrap around the image */}
            <div className="clear-both"></div>
        </div>
    </div>;
};

const Header = (props: {
    menuId: string,
    restaurantSlug: string,
    isClient?: boolean,
    item: ApiMenuItem,
}) => {

    const { item, isClient, menuId, restaurantSlug } = props;
    const [isSubscribeSelected, setIsSubscribeSelected] = React.useState(false);
    const [emailAddress, setEmailAddress] = React.useState('');
    const [addSubscriber] = useAddSubscriberMutation();
    const { t } = useClientTranslation();
    const shareTitle = t('menus.details_card.share_feature.title');
    const sharePlaceholder = t('menus.details_card.share_feature.placeholder');
    const shareCheckboxLabel = t('menus.details_card.share_feature.checkbox_label');
    const shareActionCancel = t('menus.details_card.share_feature.action_cancel');
    const shareActionSend = t('menus.details_card.share_feature.action_send');

    const handleShare = async (id: string) => {
        console.log(navigator);
        if (navigator.share) {
            const url = `https://menu.wikoti.com/${menuId}/${id}`;
            await navigator.share({
                title: 'Share',
                text: 'Check out this menu item',
                url: url
            });
        }
    };

    const name = useClientTranslatableText(item.name);
    const publishName = item.publishName ? useClientTranslatableText(item.publishName) : '';
    const maxNameLength = publishName.length > 0 ? publishName.length : name.length;

    return <div
        className={'flex flex-row w-full pt-2 md:pt-6 items-start sticky top-0 bg-backgroundAccent px-2 md:px-8 pb-2'}>
        {isClient ? <div id={'backArrow'} className={'flex justify-center mr-2 md:mr-4'}>
            <Link to={`/${menuId}`} className={'bg-button p-2 rounded-lg'}>
                <IconBack
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    className={'fill-buttonText'}
                />
            </Link>
        </div> : null}
        <div id={'title'} className={'flex flex-row w-full space-x-2 justify-start'}>
            <MedalsComponent item={item} orientation={maxNameLength > 40 ? 'vertical' : 'horizontal'} />
            <ClientTranslatableText translatables={item.publishName ? item.publishName : item.name}
                className={'text-xl md:text-4xl font-bold text-foregroundAccent'}
            />
        </div>
        {isClient && <div id={'share'} className={'flex justify-center bg-button p-2 rounded-lg'}>
            {Responsive.isMobileView() && <IconShare
                width={ICON_SIZE}
                height={ICON_SIZE}
                className={'fill-buttonText w-6 md:w-8'}
                onClick={() => {
                    void handleShare(item.id);
                }}
            />}
            {!Responsive.isMobileView() && <AlertDialog key={item.id}>
                <AlertDialogTrigger asChild>
                    <IconShare
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        className={'fill-buttonText'}
                    />
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle className={'text-lg'}>{shareTitle}</AlertDialogTitle>
                    </AlertDialogHeader>
                    <Input
                        className={'text-foreground'}
                        placeholder={sharePlaceholder}
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id={'subscribe'}
                            checked={isSubscribeSelected}
                            onCheckedChange={() => {
                                setIsSubscribeSelected(!isSubscribeSelected);
                            }}
                        >
                        </Checkbox>
                        <Label className={'text-lg text-foreground'} htmlFor="subscribe">
                            {shareCheckboxLabel}
                        </Label>

                    </div>
                    <AlertDialogFooter>
                        <AlertDialogCancel>{shareActionCancel}</AlertDialogCancel>
                        <AlertDialogAction
                            disabled={!isSubscribeSelected || !isValidEmailAddress(emailAddress)}
                            onClick={() => {
                                addSubscriber({
                                    body: {
                                        email: emailAddress,
                                        source: 'menu_item_card_share',
                                        menuId: menuId,
                                        itemId: item.id,
                                    },
                                    restaurantSlug: restaurantSlug
                                })
                                    .unwrap()
                                    .then(() => {
                                        console.log('Email address subscribed');
                                    });
                            }}
                        >
                            {shareActionSend}
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>}
        </div>}
    </div>;
};

const shouldDisplayGlassPrice = (menuItem: ApiMenuItem) => {
    return menuItem.isVisibleForPricePerGlass
        && menuItem.pricePerGlass !== undefined
        && menuItem.pricePerGlass !== 0;
};

const shouldDisplayBottlePrice = (menuItem: ApiMenuItem) => {
    return menuItem.isVisibleForPrice
        && menuItem.price !== undefined
        && menuItem.price !== 0;
};

const TopPart = (props: {
    menuId: string,
    menuItem: ApiMenuItem,
    restaurantSlug: string,
    currency: string,
    isClient?: boolean
}) => {
    const { menuItem, menuId, restaurantSlug, currency, isClient } = props;
    const { t } = useClientTranslation();
    const categoryName = t(`wine_categories.${menuItem.wineCategory?.name}`);
    const countryName = menuItem.region?.country?.name ? t(`countries.${menuItem.region.country.name}`) : '';
    const regionName = menuItem.region?.name ? t(`regions.${menuItem.region.name}`) : '';
    const grapeNames = menuItem.grapes?.map(grape => t(`grapes.${grape.name}`)).join(', ');
    const foodCategoryNames = menuItem.foodCategoryAssociation?.map(foodCategory => t(`Food_Category_Association.${foodCategory.name}`)).join(', ');
    const wineStyleNames = menuItem.style?.map(style => t(`wine_style.${style.name}`));
    const wineStyleMeanings = menuItem.style?.map(style => t(`wine_style_meaning.${style.name}`));
    const wineAromas = menuItem.aroma?.map(aroma => t(`aromas.${aroma.name}`)).join(', ');
    const wineLabel = menuItem.label?.map(label => t(`wine_labels.${label.name}`)).join(', ');
    const quantityPerBottle = QuantityFormatter.inMl(menuItem.quantity);
    const quantityPerGlass = QuantityFormatter.inMl(menuItem.quantityPerGlass ?? 0);

    const displayBottlePrice = shouldDisplayBottlePrice(menuItem);
    const displayGlassPrice = shouldDisplayGlassPrice(menuItem);

    return <div id="topPart" className="bg-backgroundAccent text-foregroundAccent min-h-[55svh]">
        <Header
            restaurantSlug={restaurantSlug}
            menuId={menuId}
            isClient={isClient}
            item={menuItem}
        />
        <div className={'flex flex-col mt-2 md:mt-8 pb-8'}>
            <div className={'flex flex-row'}>
                <div className={'ml-4 md:ml-20 w-full flex flex-col space-y-[1px] md:space-y-2'}>
                    {/*category*/}
                    {categoryName && <CategoryLine
                        category={categoryName}
                        region={formatRegionName(countryName, regionName)}
                        alcoholPercentage={menuItem.alcoholContent}
                    />}
                    <div className={'flex flex-col space-y-2 w-4/5 md:w-full'}>
                        <QuantityLine
                            perBottle={quantityPerBottle}
                            perGlass={quantityPerGlass}
                            isVisibleForPrice={menuItem.isVisibleForPrice}
                            isVisibleForPricePerGlass={menuItem.isVisibleForPricePerGlass} />
                        {/*millesime*/}
                        {menuItem.millesime?.year && <MillesimeLine millesime={menuItem.millesime.year} />}
                        {/*grapes*/}
                        {grapeNames && <GrapesLine grapeNames={grapeNames} />}
                        {/*wine style*/}
                        {wineStyleNames && wineStyleMeanings &&
                            <WineStyleLine styles={wineStyleNames} meanings={wineStyleMeanings} />}
                        {/*aromas*/}
                        {wineAromas && <WineAromaLine aromas={wineAromas} />}
                        {/*food categories association*/}
                        {foodCategoryNames && <FoodCategoryLine foodCategories={foodCategoryNames} />}
                        {/*label*/}
                        {wineLabel && <LabelLine label={wineLabel} />}
                        {/*serving temperature*/}
                        {menuItem.servingTemperature &&
                            <ServingTemperatureLine servingTemperature={menuItem.servingTemperature} />}
                        {/*price*/}
                        {(displayBottlePrice || displayGlassPrice) ? <div className={'flex flex-row items-top'}>
                            <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
                                <IconPrice width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
                            </div>
                            <div
                                className={'flex flex-col md:flex-row space-x-0 md:space-x-2 md:space-y-0 w-5/6 md:items-center'}>
                                {displayBottlePrice ? <PriceLineComponent
                                    price={menuItem.price}
                                    offer={menuItem.priceOffer}
                                    currency={currency}
                                    quantity={quantityPerBottle[0]}
                                    quantityUnit={quantityPerBottle[1]} />
                                    : null
                                }
                                {displayBottlePrice && displayGlassPrice && !Responsive.isMobileView() ?
                                    <div className={'w-[2px] h-6 bg-buttonText'}></div> : null}
                                {displayGlassPrice ? <PriceLineComponent
                                    price={menuItem.pricePerGlass}
                                    offer={menuItem.pricePerGlassOffer}
                                    currency={currency}
                                    quantity={quantityPerGlass[0]}
                                    quantityUnit={quantityPerGlass[1]} />
                                    : null
                                }
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

const CategoryLine = (
    {
        category,
        region,
        alcoholPercentage
    }: {
        category: string,
        region?: string,
        alcoholPercentage?: number
    }) => {
    return <div id={'category'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconWineType width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={cn('flex flex-row w-full')}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {category}
            </Label>
            {region && <Label className={LINE_LABEL_CLASS_NAME}>
                &nbsp;|&nbsp;{region}
            </Label>}
            {alcoholPercentage && <Label className={LINE_LABEL_CLASS_NAME}>
                &nbsp;|&nbsp;{alcoholPercentage}&nbsp;&deg;
            </Label>}
        </div>
    </div>;
};

const MillesimeLine = ({ millesime }: { millesime: number }) => {
    return <div id={'millesime'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconMillesime width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {millesime}
            </Label>
        </div>
    </div>;
};

const QuantityLine = ({ perBottle, perGlass, isVisibleForPrice, isVisibleForPricePerGlass }: {
    perBottle?: [number | undefined, string],
    perGlass?: [number | undefined, string],
    isVisibleForPrice?: boolean,
    isVisibleForPricePerGlass?: boolean
}) => {
    // only show the quantity if the item is visible for that price
    let text = '';
    if (isVisibleForPrice && perBottle?.[0]) {
        text = `${perBottle[0]} ${perBottle[1]}`;
    }
    if (isVisibleForPricePerGlass && perGlass?.[0]) {
        if (text) {
            text += ', ';
        }
        text += `${perGlass[0]} ${perGlass[1]}`;
    }

    if (!text) {
        return null;
    }

    return <div id={'content'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconWeight width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {text}
            </Label>
        </div>
    </div>;
};

const GrapesLine = (props: {
    grapeNames: string
}) => {
    const { grapeNames } = props;
    return <div id={'grapes'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconGrapes width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {grapeNames}
            </Label>
        </div>
    </div>;
};

const WineStyleLine = ({ styles, meanings }: { styles: string[], meanings: string[] }) => {
    const { t } = useClientTranslation();
    const panelClose = t('wine_style_meaning_panel.close');
    return <div id={'wine_style'} className={'flex flex-row'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconWineStyle width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={cn(PROPERTY_DESCRIPTION_CLASS_NAME, 'flex flex-wrap justify-start')}>
            {styles.map((style, index) => {
                const panelTitle = t('wine_style_meaning_panel.title', { name: style });
                return <AlertDialog key={`${style}_${index}`}>
                    <AlertDialogTrigger asChild>
                        <Badge variant={'outline'}
                            className={cn(LINE_LABEL_CLASS_NAME, 'mb-2 mr-2 font-normal text-foregroundAccent')}>
                            {style}
                        </Badge>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle className={'text-lg'}>{panelTitle}</AlertDialogTitle>
                            <AlertDialogDescription className={'text-lg'}>
                                {meanings[index]}
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogAction>{panelClose}</AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>;
            })}
        </div>
    </div>;
};

const WineAromaLine = ({ aromas }: { aromas: string }) => {
    return <div id={'wine_aroma'} className={'flex flex-row items-top'}>
        <div id={'image'} className={'mr-4'}>
            <IconWineAroma width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {aromas}
            </Label>
        </div>
    </div>;
};

const FoodCategoryLine = ({ foodCategories }: { foodCategories: string }) => {
    return <div id={'food_category_pairing'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconPairing width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {foodCategories}
            </Label>
        </div>
    </div>;
};

const LabelLine = ({ label }: { label: string }) => {
    return <div id={'wine_label'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconLabel width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {label}
            </Label>
        </div>
    </div>;
};

const ServingTemperatureLine = ({ servingTemperature }: { servingTemperature: string }) => {
    return <div id={'wine_label'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            <IconThermometer width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {servingTemperature}
            </Label>
        </div>
    </div>;
};


const formatRegionName = (countryName: string, wineRegionName: string): string => {
    if (!countryName && !wineRegionName) return '';
    if (!countryName) return wineRegionName;
    if (!wineRegionName) return countryName;
    return `${countryName} - ${wineRegionName}`;
};
