'use client';
import { ApiMenuItem } from '@repo/api/model';
import { useClientTranslation } from '@repo/internationalization/client';
import { ClientTranslatableText, Label, useClientTranslatableText } from '@repo/ui';
import { currencyMap } from '@repo/utils';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { setSelectedItemId } from '../../store/app-state-slice';
import { PriceDisplay } from '../price-display';
import { MedalsComponent } from './medals-component';
import { MenuItemTitleComponent } from './menu-item-title-component';

const QuantityComponent = (props: { quantity: number | undefined }) => {
    const {quantity} = props;
    return <Label className={'text-2xl text-right'}>
        ({quantity})
    </Label>;
};

const shouldDisplayPrice = (menuItem: ApiMenuItem, isBottleGlassFilterOn: boolean, byGlass: boolean) => {
    const isValidPrice =
        menuItem.price !== undefined
        && menuItem.price !== 0
        && menuItem.quantity !== undefined;
    if (isBottleGlassFilterOn && byGlass) {
        return false;
    }
    return isValidPrice;
};

export const MiscItemRow = memo((props: {
    item: ApiMenuItem;
}) => {
    const {item} = props;

    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const viewDetailsLabel = t('menus.item_list.view_details');
    const byGlass = useAppSelector(state => state.appState.appliedFilters.byGlass) as boolean;
    const isBottleGlassFilterOn = useAppSelector(state => state.appState.menuTemplate?.bottleGlassFilter) ?? false;
    const currency = useAppSelector(state => state.appState.currency);
    const name = useClientTranslatableText(item.name);
    const publishName = item.publishName ? useClientTranslatableText(item.publishName) : '';
    const maxNameLength = publishName.length > 0 ? publishName.length : name.length;

    return <Link
        onClick={() => {
            window.scrollTo(0, 0);
            dispatch(setSelectedItemId(item.id));
        }}
        id={item.id}
        to={`${item.id}`}
        key={item.id}
        className={'flex flex-col my-2'}
    >
        <div className={'flex flex-row justify-between'}>
            <div className={'flex flex-row items-center'}>
                <MedalsComponent item={item} orientation={maxNameLength > 35 ? 'vertical' : 'horizontal'}/>
                <MenuItemTitleComponent item={item}/>
            </div>
            <div className={'flex flex-col'}>
                <div id="bottlePrice" className={'ml-4 flex flex-col'}>
                    {shouldDisplayPrice(item, isBottleGlassFilterOn, byGlass) &&
                        <div className={'flex flex-row space-x-2 justify-end'}>
                            <PriceDisplay price={item.price} currency={currencyMap[currency] ?? ''}
                                          offer={item.priceOffer}
                                          textClassName={'text-2xl font-bold'}/>
                            <QuantityComponent quantity={item.quantity}/>
                        </div>
                    }
                </div>
            </div>
        </div>
        <ClientTranslatableText className={'text-xl mt-4'} translatables={item.shortDescription}/>
        <div className={'flex flex-row w-full justify-end underline text-button'}>{viewDetailsLabel}</div>
    </Link>;
});
