import {ApiMenuItem} from '@repo/api/model';
import {ClientTranslatableText} from '@repo/ui';

export const MenuItemTitleComponent = (props: { item: ApiMenuItem }) => {
    const {item} = props;
    return <h3 id="title">
        {item.publishName ?
            <ClientTranslatableText
                translatables={item.publishName}
                className={'text-lg md:text-2xl font-bold'}
            /> : <ClientTranslatableText
                translatables={item.name}
                className={'text-lg md:text-2xl font-bold'}
            />}
    </h3>;
};
