import * as React from 'react';
import {SVGProps} from 'react';

export const IconRegion = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            d="M24 2a22 22 0 1 0 22 22A22 22 0 0 0 24 2zm-8.943 4.13 1.193.828a1.932 1.932 0 0 1 .622.718l.3.6a1.917 1.917 0 0 0 1.716 1.061h2.133a1.919 1.919 0 0 1 1.919 1.919v.038a1.916 1.916 0 0 1-1.061 1.716l-5.117 2.56a1.763 1.763 0 0 0-.207.119l-2.619 1.746a1.931 1.931 0 0 0-.653.739l-.389.782a1.9 1.9 0 0 0 0 1.715 1.921 1.921 0 0 1-.361 2.216l-.057.058a1.918 1.918 0 0 1-2.344.288l-3.347-2.01a1.922 1.922 0 0 1-.833-1.039l-.823-2.47c-.038 0-.065-.012-.1-.013A20.031 20.031 0 0 1 15.057 6.13zm11.024 24.993a1.292 1.292 0 0 1-.066.409l-.885 2.654a1.289 1.289 0 0 1-.312.506l-1.6 1.6a1.289 1.289 0 0 0-.354.662l-.847 4.232a1.293 1.293 0 0 1-.691.9l-.694.348a1.3 1.3 0 0 1-1.495-.243l-1.286-1.286a1.321 1.321 0 0 1-.243-.337l-.817-1.634a1.307 1.307 0 0 1-.136-.579v-4.126a1.3 1.3 0 0 0-.716-1.159l-1.072-.535a1.29 1.29 0 0 1-.5-.44l-1.687-2.531a1.293 1.293 0 0 1-.218-.718v-.9a1.3 1.3 0 0 1 .716-1.158l.993-.5a1.288 1.288 0 0 0 .579-.579l.5-.993A1.3 1.3 0 0 1 16.405 24h1.806a1.294 1.294 0 0 1 .916.379l.289.289a1.3 1.3 0 0 0 .916.38h.268a1.3 1.3 0 0 1 1.295 1.3 1.3 1.3 0 0 0 .715 1.158l2.76 1.379a1.3 1.3 0 0 1 .715 1.159zm5.27-19.009a1.784 1.784 0 0 1 .385-.578l1.791-1.79a1.791 1.791 0 0 1 .461-.333l1.88-.94a1.753 1.753 0 0 1 .52-.155 20.057 20.057 0 0 1 5.248 6.253h-.884a1.779 1.779 0 0 1-1.257-.52l-.216-.216a1.78 1.78 0 0 0-1.257-.521h-1.358a1.775 1.775 0 0 0-.8.188l-1.762.882a1.777 1.777 0 0 1-.8.187h-.317a1.778 1.778 0 0 1-1.642-2.457zm7.539 25.205L38.76 37a2.106 2.106 0 0 1-.107-.663v-2.309a2.1 2.1 0 0 0-.221-.937l-1.654-3.306a2.1 2.1 0 0 1-.221-.937v-.306a2.095 2.095 0 0 0-3.031-1.874l-.717.359a2.1 2.1 0 0 1-.936.221h-.091a2.1 2.1 0 0 1-1.988-1.434l-.462-1.387a2.1 2.1 0 0 1-.108-.663v-1.681a2.094 2.094 0 0 1 .933-1.743l1.908-1.272a2.085 2.085 0 0 1 .654-.289l3.246-.812a2.1 2.1 0 0 1 1.172.045l2.24.747a2.109 2.109 0 0 0 .664.108h3.269a19.789 19.789 0 0 1-4.42 18.452z"/>
    </svg>
);
