import {PriceDisplay} from '../price-display';
import {Label} from '@repo/ui';

export const PriceLineComponent = ({price, offer, currency, quantity, quantityUnit}: {
    price?: number,
    offer?: number,
    currency: string,
    quantity?: number,
    quantityUnit: string
}) => {

    return <div id={'price'} className={'flex flex-row space-x-2'}>
        <PriceDisplay textClassName={'text-lg md:text-2xl text-foregroundAccent'} price={price} offer={offer}
                      currency={currency}/>
        {quantity ? <Label
            className={'text-lg md:text-2xl text-foregroundAccent'}>{`(${quantity}\u00A0${quantityUnit})`}</Label> : null}
    </div>;
};
