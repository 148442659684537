'use client';

import Image from 'next/image';
import React from 'react';
import { ApiMenuItem, ApiMenuItemDisplayType, ApiMenuItemIngredient, ApiTranslatable } from '@repo/api/model';
import { useClientTranslation } from '@repo/internationalization/client';
import { ClientTranslatableText, cn, getTargetTranslatableText, Label, useClientTranslatableText } from '@repo/ui';
import { useAddSubscriberMutation } from '../../store/api/restautant-api';
import { Link } from 'react-router-dom';
import { IconBack } from '../../assets/icons/icon-back';
import { IconLabel } from '../../assets/icons/icon-label';
import { IconQuantity } from '../../assets/icons/icon-quantity';
import { IconWeight } from '../../assets/icons/icon-weight';
import { IconCalories } from '../../assets/icons/icon-calories';
import { IconIngredients } from '../../assets/icons/icon-ingredients';
import { IconAllergens } from '../../assets/icons/icon-allergens';
import { PriceLineComponent } from './price-line-component';
import { IconPrice } from '../../assets/icons/icon-price';
import { MedalsComponent } from '../menu';
import { useCookies } from 'next-client-cookies';
import { LogIf, QuantityFormatter, Responsive } from '@repo/utils';

const ICON_SIZE = 30;
const LINE_LABEL_CLASS_NAME = 'text-lg md:text-2xl text-foregroundAccent';
const PROPERTY_ICON_CLASS_NAME = 'fill-foregroundAccent w-6 md:w-6 h-8 md:h-8';
const PROPERTY_ICON_WRAPPER_CLASS_NAME = 'mr-2 md:mr-4 items-top';
const PROPERTY_DESCRIPTION_CLASS_NAME = 'w-3/4 lg:w-5/6';

export interface FoodDetailsCardProps {
    menuId: string;
    restaurantSlug: string;
    item: ApiMenuItem;
    currency: string;
    style?: any;
    isClient?: boolean;
}

const isValidEmailAddress = (emailAddress: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
};

export const FoodDetailsCard = (props: FoodDetailsCardProps) => {

    const { item, menuId, restaurantSlug, currency, style, isClient } = props;

    const imageClassName = cn(
        'object-contain rounded-xl',
        'mt-[-4rem] mt-0 md:mr-4',
        'w-68 md:w-[50vw] lg:w-96 xl:w-96 2xl:w-[30vw] mt-10 md:mt-0'
    );
    const bottomPartClassName = cn(
        'mt-8',
        'px-4 md:px-32'
    );
    return <div className={'overflow-y-scroll no-scrollbar'} style={style}>
        <div className='bg-backgroundAccent text-foregroundAccent px-4 md:px-32'>
            <TopPart
                menuId={menuId}
                restaurantSlug={restaurantSlug}
                menuItem={item}
                currency={currency}
                isClient={isClient}
            />
        </div>

        {/* Bottom Part */}
        <div id="bottomPart" className={bottomPartClassName}>
            {/* Image that overlaps top and bottom parts */}
            {item.image && (
                <div className="flex flex-col justify-center items-center md:float-right mt-[-100px] py-4">
                    <Image
                        src={item.image.downloadURL}
                        alt=""
                        priority
                        className={imageClassName}
                        width={600}
                        height={600}
                    />
                </div>
            )}
            {/* Description */}
            {['SZHbaCRLqVRgzH1h9hfR', 'SZHbaCRLqVRgzH1h9hfS', '0adOaOuO5nMddinBanTi'].includes(item.id) ?
                <ClientTranslatableText
                    className={'text-lg md:text-2xl'}
                    translatables={item.longDescription}
                />
                : null
            }
            {/* Additional content here will wrap around the image */}
            <div className="clear-both"></div>
            <hr className={'border-b-2 border-backgroundAccent mr-4 my-4'} />
            <div className={'flex flex-col md:flex-row justify-between'}>
                <NutritionalValueDeclaration item={item} />
                <IngredientsDeclaration item={item} />
            </div>
        </div>
    </div>;
};

const Header = (props: {
    menuId: string,
    restaurantSlug: string,
    isClient?: boolean,
    item: ApiMenuItem,
}) => {

    const { item } = props;
    const [isSubscribeSelected, setIsSubscribeSelected] = React.useState(false);
    const [emailAddress, setEmailAddress] = React.useState('');
    const [addSubscriber] = useAddSubscriberMutation();

    const handleShare = async (id: string) => {
        console.log(navigator);
        if (navigator.share) {
            const url = `${window.location.origin}/${props.menuId}/${id}`;
            await navigator.share({
                title: 'Share',
                text: 'Check out this menu item',
                url: url
            });
        }
    };

    const name = useClientTranslatableText(item.name);
    const publishName = item.publishName ? useClientTranslatableText(item.publishName) : '';
    const maxNameLength = publishName.length > 0 ? publishName.length : name.length;

    const headerClassName = cn(
        'flex flex-row w-full pt-2 md:pt-6 sticky top-0',
    );
    return <div className={headerClassName}>
        {props.isClient && <div id={'backArrow'} className={'flex justify-center mr-2 md:mr-4'}>
            <Link to={`/${props.menuId}`} className={'bg-button p-2 rounded-lg'}>
                <IconBack
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    className={'fill-buttonText'}
                />
            </Link>
        </div>}
        <div id={'title'} className={'flex flex-row w-full space-x-2 items-center justify-center'}>
            <MedalsComponent item={item} orientation={maxNameLength > 40 ? 'vertical' : 'horizontal'} />
            <ClientTranslatableText translatables={item.publishName ? item.publishName : item.name}
                className={'text-xl md:text-4xl font-bold text-foregroundAccent'}
            />
        </div>
        {/*{props.isClient && <div id={'share'} className={'flex justify-center bg-button p-2 rounded-lg'}>*/}
        {/*    {Responsive.isMobileView() && <IconShare*/}
        {/*        width={ICON_SIZE}*/}
        {/*        height={ICON_SIZE}*/}
        {/*        className={'fill-buttonText w-6 md:w-8'}*/}
        {/*        onClick={() => {*/}
        {/*            void handleShare(props.menuItemId);*/}
        {/*        }}*/}
        {/*    />}*/}
        {/*    {!Responsive.isMobileView() && <AlertDialog key={props.menuItemId}>*/}
        {/*        <AlertDialogTrigger asChild>*/}
        {/*            <IconShare*/}
        {/*                width={ICON_SIZE}*/}
        {/*                height={ICON_SIZE}*/}
        {/*                className={'fill-buttonText'}*/}
        {/*            />*/}
        {/*        </AlertDialogTrigger>*/}
        {/*        <AlertDialogContent>*/}
        {/*            <AlertDialogHeader>*/}
        {/*                <AlertDialogTitle className={'text-lg'}>Enter your email address to receive this wine card by*/}
        {/*                    email</AlertDialogTitle>*/}
        {/*            </AlertDialogHeader>*/}
        {/*            <Input placeholder={'Your email address'} value={emailAddress}*/}
        {/*                   onChange={(e) => setEmailAddress(e.target.value)}/>*/}
        {/*            <div className="flex items-center space-x-2">*/}
        {/*                <Checkbox*/}
        {/*                    id={'subscribe'}*/}
        {/*                    checked={isSubscribeSelected}*/}
        {/*                    onCheckedChange={() => {*/}
        {/*                        setIsSubscribeSelected(!isSubscribeSelected);*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                </Checkbox>*/}
        {/*                <Label className={'text-lg'} htmlFor="subscribe">*/}
        {/*                    Subscribe to our newsletter*/}
        {/*                </Label>*/}

        {/*            </div>*/}
        {/*            <AlertDialogFooter>*/}
        {/*                <AlertDialogCancel>Cancel</AlertDialogCancel>*/}
        {/*                <AlertDialogAction*/}
        {/*                    disabled={!isSubscribeSelected || !isValidEmailAddress(emailAddress)}*/}
        {/*                    onClick={() => {*/}
        {/*                        addSubscriber({*/}
        {/*                            body: {*/}
        {/*                                email: emailAddress,*/}
        {/*                                source: 'menu_item_card_share',*/}
        {/*                                menuId: props.menuId,*/}
        {/*                                itemId: props.menuItemId,*/}
        {/*                            },*/}
        {/*                            restaurantSlug: props.restaurantSlug*/}
        {/*                        })*/}
        {/*                            .unwrap()*/}
        {/*                            .then(() => {*/}
        {/*                                console.log('Email address subscribed');*/}
        {/*                            });*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    SEND*/}
        {/*                </AlertDialogAction>*/}
        {/*            </AlertDialogFooter>*/}
        {/*        </AlertDialogContent>*/}
        {/*    </AlertDialog>}*/}
        {/*</div>}*/}
    </div>;
};

const TopPart = (props: {
    menuId: string,
    menuItem: ApiMenuItem,
    restaurantSlug: string,
    currency: string,
    isClient?: boolean
}) => {
    const { menuItem, menuId, restaurantSlug, currency, isClient } = props;
    const { t } = useClientTranslation();
    const cookies = useCookies();
    const lang = cookies.get('lang') ?? 'EN';
    const portions = menuItem.portions ?? 1;
    const energyValueCal = menuItem.energyValueCal;
    const labels = menuItem.label?.map(label => t(`food_labels.${label.name}`)).join(', ');
    const portionsLabelOne = t('menus.details_card.quantity_label.piece');
    const portionsLabelMany = t('menus.details_card.quantity_label.pieces');

    let [quantity, unit] = QuantityFormatter.inGrams(menuItem.quantity);
    if (menuItem.displayType === ApiMenuItemDisplayType.DRINK || menuItem.displayType === ApiMenuItemDisplayType.COCKTAIL) {
        [quantity, unit] = QuantityFormatter.inMl(menuItem.quantity);
    }
    if (menuItem.displayType === ApiMenuItemDisplayType.MISC) {
        [quantity, unit] = [menuItem.quantity, ''];
    }

    const ingredients = menuItem.ingredients?.map(ingredient => getTargetTranslatableText({
        targetLang: lang,
        translatables: ingredient.name
    })).join(', ');
    const allergens = menuItem.allergens?.map(allergen => t(`allergens.${allergen.code}`)).join(', ');

    const contentClassName = cn(
        'flex flex-col',
        'mt-2 md:mt-8',
        'pb-8'
    );

    const propertiesClassName = cn(
        'flex flex-col space-y-2',
        'w-[60vw] md:w-[40vw] xl:w-[30vw]'
    );
    return <div id="topPart" className="min-h-[25svh]">
        <Header
            restaurantSlug={restaurantSlug}
            menuId={menuId}
            isClient={isClient}
            item={menuItem}
        />

        <div className={contentClassName}>
            <div className={'flex flex-row'}>
                <div className={'w-full flex flex-col space-y-[1px] md:space-y-2'}>
                    <div className={propertiesClassName}>
                        {portions ?
                            <ContentLine text={`${portions} ${portions === 1 ? portionsLabelOne : portionsLabelMany}`}
                                Icon={<IconQuantity width={ICON_SIZE} height={ICON_SIZE}
                                    className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {quantity ? <ContentLine text={`${quantity} ${unit}`}
                            Icon={<IconWeight width={ICON_SIZE} height={ICON_SIZE}
                                className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {energyValueCal ? <ContentLine text={`${energyValueCal} kcal`}
                            Icon={<IconCalories width={ICON_SIZE} height={ICON_SIZE}
                                className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {labels ? <ContentLine text={labels} Icon={<IconLabel width={ICON_SIZE} height={ICON_SIZE}
                            className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {ingredients ? <ContentLine text={ingredients}
                            Icon={<IconIngredients width={ICON_SIZE} height={ICON_SIZE}
                                className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {allergens ? <ContentLine text={allergens}
                            Icon={<IconAllergens width={ICON_SIZE} height={ICON_SIZE}
                                className={PROPERTY_ICON_CLASS_NAME} />} />
                            : null
                        }
                        {shouldDisplayPrice(menuItem) ? <div className={'flex flex-row items-top'}>
                            <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
                                <IconPrice width={ICON_SIZE} height={ICON_SIZE} className={PROPERTY_ICON_CLASS_NAME} />
                            </div>
                            <div className={'flex flex-row space-x-2 w-5/6'}>
                                <PriceLineComponent price={menuItem.price}
                                    offer={menuItem.priceOffer}
                                    currency={currency}
                                    quantity={quantity}
                                    quantityUnit={unit} />
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

const ContentLine = ({ text, Icon }: { text: string, Icon: JSX.Element }) => {
    return <div id={'content'} className={'flex flex-row items-top'}>
        <div id={'image'} className={PROPERTY_ICON_WRAPPER_CLASS_NAME}>
            {Icon}
        </div>
        <div id={'description'} className={PROPERTY_DESCRIPTION_CLASS_NAME}>
            <Label className={LINE_LABEL_CLASS_NAME}>
                {text}
            </Label>
        </div>
    </div>;
};

const shouldDisplayPrice = (menuItem: ApiMenuItem) => {
    return menuItem.isVisibleForPrice
        && menuItem.price !== undefined
        && menuItem.price !== 0;
};

const NutritionalValueDeclaration = (props: { item: ApiMenuItem }) => {
    const { item } = props;
    const { t } = useClientTranslation();

    const nutritionalDeclarationLabel = t('menus.details_card.nutritional_declaration');
    const energyValueLabel = t('menus.details_card.energy_value');
    const fatGramsLabel = t('menus.details_card.fat');
    const saturatedFatGramsLabel = t('menus.details_card.saturated_fat');
    const glucoseGramsLabel = t('menus.details_card.carbohydrates');
    const sugarGramsLabel = t('menus.details_card.sugar');
    const proteinGramsLabel = t('menus.details_card.protein');
    const saltGramsLabel = t('menus.details_card.salt');

    const quantityGramsLabel = t(`menus.measurement_unit.grams`);

    // if none of the nutritional values are defined, return null
    if (!(item.energyValueCal || item.energyValueCal === 0)
        && !(item.fatGrams || item.fatGrams === 0)
        && !(item.saturatedFatGrams || item.saturatedFatGrams === 0)
        && !(item.glucoseGrams || item.glucoseGrams === 0)
        && !(item.sugarGrams || item.sugarGrams === 0)
        && !(item.proteinGrams || item.proteinGrams === 0)
        && !(item.saltGrams || item.saltGrams === 0)) {
        return null;
    }

    return <div id={'nutritional-declaration'} className={'flex flex-col'}>
        <div className={'flex flex-col space-y-2'}>
            <Label className={'text-2xl bold'}>{nutritionalDeclarationLabel}</Label>
            <div className={'flex flex-col'}>
                {(item.energyValueCal || item.energyValueCal === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{energyValueLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.energyValueCal}</Label>
                            <Label className={'text-lg'}>kcal</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.fatGrams || item.fatGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{fatGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.fatGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.saturatedFatGrams || item.saturatedFatGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{saturatedFatGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.saturatedFatGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.glucoseGrams || item.glucoseGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{glucoseGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.glucoseGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.sugarGrams || item.sugarGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{sugarGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.sugarGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.proteinGrams || item.proteinGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{proteinGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.proteinGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
                {(item.saltGrams || item.saltGrams === 0) ? <div className={'flex flex-row space-x-2'}>
                    <Label className={'text-lg'}>{saltGramsLabel}:</Label>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-row space-x-2'}>
                            <Label className={'text-lg'}>{item.saltGrams}</Label>
                            <Label className={'text-lg'}>{quantityGramsLabel}</Label>
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    </div>;
};


const IngredientRow = (props: { index: number, ingredient: ApiMenuItemIngredient }) => {
    const { index, ingredient } = props;
    const { t } = useClientTranslation();
    const quantityLabel = t(`menus.measurement_unit.${ingredient.unit}`);
    return <div key={index} className={'flex flex-row'}>
        <div className={'flex flex-row'}>
            <ClientTranslatableText
                className={'text-lg capitalize'}
                key={index}
                translatables={ingredient.name}
            />
        </div>
        {ingredient.weight ? <div className={'flex flex-col'}>
            <div className={'flex flex-row'}>
                <Label className={'text-lg'}>:&nbsp;</Label>
                <Label className={'text-lg'}>{ingredient.weight}</Label>
                <Label className={'text-lg'}>&nbsp;{quantityLabel}</Label>
            </div>
        </div> : null}
    </div>;
};

const IngredientsDeclaration = (props: { item: ApiMenuItem }) => {
    const { item } = props;
    const { t } = useClientTranslation();
    const ingredientsLabel = t('menus.details_card.ingredients');

    // if no ingredients are defined, return null
    if (!item.ingredients || !item.ingredients.length) {
        return null;
    }

    return <div id={'ingredients'} className={'flex flex-col space-y-2 pr-4 mt-4 md:mt-0'}>
        <Label className={'text-2xl bold'}>{ingredientsLabel}</Label>
        <div className={'flex flex-col space-y-2'}>
            {item.ingredients && item.ingredients.map((ingredient, index) => {
                return <IngredientRow
                    key={index}
                    index={index}
                    ingredient={ingredient}
                />;
            })}
        </div>
    </div>;
};
