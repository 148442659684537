'use client';
import {Label} from '@repo/ui';
import {useClientTranslation} from '@repo/internationalization/client';

export const QuantityComponent = (props: { quantity: number, unit: string }) => {
    const {quantity, unit} = props;
    const {t} = useClientTranslation();
    const quantityLabel = t(`menus.measurement_unit.${unit}`);
    return <Label className={'text-lg md:text-2xl text-right'}>
        ({quantity}&nbsp;{quantityLabel})
    </Label>;
};
