'use client';
import {Input} from '@repo/ui';
import {useClientTranslation} from '@repo/internationalization/client';
import {useAppDispatch, useAppSelector} from '../../../../store';
import {setAppliedFilters} from '../../../../store/app-state-slice';


export const SearchFilterComponent = () => {
    const dispatch = useAppDispatch();
    const {t} = useClientTranslation();
    const {appliedFilters} = useAppSelector(state => state.appState);
    const searchPlaceholderLabel = t('menus.filterPanel.search.placeholder.label');
    return <div className={''}>
        <Input
            className={'w-full md:w-60 bg-button text-buttonText border-buttonText border-[1px] rounded-md'}
            placeholder={searchPlaceholderLabel}
            value={String(appliedFilters.name)}
            onChange={(e) => {
                dispatch(setAppliedFilters({
                    ...appliedFilters,
                    name: e.target.value,
                }));
            }}
        />
    </div>;
};