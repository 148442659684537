import * as React from 'react';
import {SVGProps} from 'react';

export const IconLabel = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.983 511.983"
        {...props}
    >
        <path
            d="M270.914 157.038v110.348C373.57 173.297 347.576 74.201 255.929 0c-91.976 74.667-117.453 173.697-15.015 267.459V157.038zM165.55 307.122l75.161 75.161c-2.777-107.462-69.966-169.92-199.714-157.014-10.543 102.987 26.75 201.221 168.098 199.937h.01c12.424 0 22.96-.811 30.227-1.592l.027-.257-95.021-95.022zM367.521 328.335 272.5 423.357l.027.257c161.917 16.297 210.586-84.92 198.336-198.344-123.843-12.364-192.542 44.341-199.065 146.029a266.32 266.32 0 0 0-.65 10.985l75.161-75.161zM270.929 424.927l-.015.014v1.622h-29.985v85.42h30z"/>
    </svg>
);
