import * as React from 'react';
import {SVGProps} from 'react';

export const IconCalories = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_504_2613)">
            <path
                d="M10.0009 13.7147C9.40984 14.1544 8.18945 15.2418 8.18945 16.7233C8.18945 17.7014 9.00164 18.4972 10 18.4972C10.9984 18.4972 11.8105 17.7014 11.8105 16.7233C11.8105 15.3203 10.5895 14.1818 10.0009 13.7147Z"
                fill="currentColor"
            />
            <path
                d="M14.2432 3.50029C14.0237 3.25759 13.6447 3.24289 13.4056 3.46104L10.6483 5.97618V0.708938C10.6483 0.26802 10.1044 -0.0614186 9.69705 0.179409C9.61994 0.224993 7.7876 1.31857 5.93615 3.30447C3.68502 5.71916 2.15186 8.65922 2.15186 11.9564C2.15186 16.1961 5.67244 19.6453 9.99978 19.6453C8.35525 19.6453 7.01736 18.3345 7.01736 16.7233C7.01736 14.1199 9.60361 12.5783 9.71369 12.5139C9.90666 12.4012 10.1483 12.4065 10.3359 12.5273C10.444 12.5969 12.9822 14.2609 12.9822 16.7233C12.9822 18.3345 11.6443 19.6453 9.99978 19.6453C14.3271 19.6453 17.8477 16.1961 17.8477 11.9564C17.8477 8.75504 16.4023 5.88562 14.2432 3.50029Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_504_2613">
                <rect width="20" height="19.5472" fill="currentColor" transform="translate(0 0.0981445)"/>
            </clipPath>
        </defs>
    </svg>
);
